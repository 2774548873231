import { Table } from "antd"
import { USER_RECENT_LOANS } from "../PendingLoans/penColumns"

export default function UserRecentLoans({recentLoans}){


	return (
		<div>
           <p className="mb-4 text-2xl font-bold mt-10">Recent Loans</p>
            <div className="max-w-[1024px]">
                <Table columns={USER_RECENT_LOANS} dataSource={recentLoans} scroll={{x: 1300}}/>
            </div>
		</div>
	)
}
