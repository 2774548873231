import React, { useMemo, useState } from "react"
import { useQuery } from "react-query"
import { getPendingLoans } from "services/LoanService"
import { getPendingColumns } from "./penColumns"
import { TableContainer } from "../style"
import FilterComp from "../FilterComp"
import FullScreenLoader from "components/Dashboard/FullScreenLoader"
import LoanFilter from "components/Dashboard/LoanFIlter"
import { Table } from "antd"
import { usePaginate } from "hooks/usePagination"


const PendLoansComp = () => {
	const [filter, setFilter] = useState("all")
	const [loanFilter,setLoanFilter] = useState("all")
	const [searchQuery,setSearchQuery] = useState("")
	const { data: pendLoans, isLoading,refetch } = useQuery("pending-loans", getPendingLoans)


	const columns = useMemo(() =>getPendingColumns(refetch,loanFilter === "edit"),[refetch,loanFilter])
	const data = useMemo(() => {
		if(!pendLoans) return [];
		const loans = pendLoans.data
		const editRequestLoans = loans.filter(loanItem=>loanItem.edit_requested)
		const allLoans = (loanFilter === "all") ? loans : editRequestLoans

		if(filter === "accepted") return allLoans.filter(loanItem => loanItem.lo_status === "accepted")
		if(filter === "viewed") return allLoans.filter(loanItem => loanItem.lo_status === "reviewed")
		if(filter === "not-viewed") return allLoans.filter(loanItem => loanItem.lo_status === "")

		return allLoans
	}, [pendLoans,loanFilter,filter])


	const filterOptions = [
		{value: "all", label: "All Loans",},
		{value: "viewed", label: "Viewed Loans",},
		{value: "not-viewed", label: "Unviewed Loans",},
		{value: "accepted", label: "Accepted Loans",},
	]

	const filterData = (arrData)=>{
		return arrData.filter((loan)=>{
			if(!loan) return true;
			return loan.applicant.first_name.toLowerCase().includes(searchQuery.toLowerCase())
			|| loan.applicant.surname.toLowerCase().includes(searchQuery.toLowerCase())
		})
	}

	const getLoanStats = ()=>{

		const allLoans = data.map(loan=>loan.amount)
		return {
			total:data.length,
			totalAmount:allLoans.reduce((a,b)=>a+b,0),
		}
	}

	const {pageData,currentPage,setPage,next,prev} = usePaginate(data)


	if (isLoading) return <FullScreenLoader show={true} />
	return (
		<>
			<TableContainer>
				<div className="flex items-center justify-between gap-8 my-4">
					<div className="flex flex-1 items-center gap-2">
						<button onClick={()=>setLoanFilter("all")}  className={`py-2 px-4 min-w-[100px] rounded-md ${loanFilter === "all"?"bg-primary text-white":"bg-gray-200 border border-solid border-gray-400"}`}>All</button>
						<button onClick={()=>setLoanFilter("edit")} className={`py-2 px-6 rounded-md ${loanFilter === "edit"?"bg-primary text-white":"bg-gray-200 border border-solid border-gray-400"}`}>Edit Requested</button>
					</div>
					<div className="flex-1">
						<FilterComp filter={searchQuery} setFilter={setSearchQuery} />
					</div>
					<LoanFilter filter={filter} options={filterOptions} handleFilter={setFilter}/>
				</div>
				<div className="max-w-[1024px]">
					<Table
						pagination={{
							current:currentPage,
							onChange:(pageNumber)=>{
								setPage(pageNumber)
							},
							pageSize:10,
							total:data.length,
							nextIcon:()=>{
								return (
									<button onClick={next}> <i className="bi bi-chevron-right"></i></button>
								)
							},
							prevIcon:()=>{
								return (
									<button onClick={prev}> <i className="bi bi-chevron-left"></i></button>
								)
							}
						}}
						loading={isLoading}
						columns={columns}
						dataSource={filterData(pageData)}
						scroll={{x: 1300}}
						footer={()=>{
						const {totalAmount,total} = getLoanStats()
							return (
								<div className="flex items-center  justify-between">
									<p><span className="font-medium">Total Loan Amount</span> : ₦{totalAmount.toLocaleString()}</p>
									<p><span className="font-medium">Total Loan</span> :{total}</p>
								</div>
							);
						}}
					/>
				</div>
			</TableContainer>
		</>
	)
}

export default PendLoansComp
