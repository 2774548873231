import styled from "styled-components/macro"
import media from "utils/media"

export const HomeContainer = styled.div`
	padding: 0px 0px;
`
export const DCardContainer = styled.div`
	width: 100%;
	display: grid;
	padding: 1rem 0rem;
	grid-template-columns: repeat(4, 1fr);
	grid-template-rows: repeat(1, 1fr);
	grid-column-gap: 10px;
	grid-row-gap: 10px;
	place-items: center;
	align-items: center;
	${media.tablet`
  width: 100%;
  flex-wrap: wrap;
    flex-direction: row;

    /* padding-right:20px;
    padding-left:20px; */
        justify-content:center;
    `};
	${media.mobile`
    width: 98%;
    flex-direction: column;
        justify-content:flex-start;
    `};
`
export const HomeLeft = styled.div`
	width: 58%;

	position: relative;
`
export const HomeRight = styled.div`
	width: 40%;
`
