import React from "react"
import dayjs from "dayjs"
import CommaNumber from "comma-number"
import { CSVLink } from "react-csv"
import { Loandetails } from "./style"
import { sumData } from "utils"
// import EmptyDataUi from "components/EmptyDoc"
// import SendMemoComp from "components/Dashboard/Loan/SendMemoComp"
import { useState } from "react"
import Tabledetails from "./LoanDetailstable"
import {CheckBox} from "components/Checkbox"
import { Table } from "antd"
import { MEMO_COLUMNS_V2 } from "components/Dashboard/Loan/RefferedLoans/appColumns"


function LoanAppTable({ tableData, title = "", role,selectedLoans,selectLoan }) {
	const filteredLoan = tableData.filter(loan=>!loan.esLoans)

	const [show, setShow] = useState(false)
	const [selected, setSelected] = useState({})

	function calculate_age(dob) {
		const g = new Date(dob)
		var diff_ms = Date.now() - g.getTime()
		var age_dt = new Date(Date.now() - diff_ms)
		return Math.abs(age_dt.getUTCFullYear() - new Date().getFullYear())
	}
	// const showDetails = payload => {
	// 	setShow(true)
	// 	setSelected(payload)
	// }
	const closeDetails = () => {
		setShow(false)
		setSelected({})
	}
	const totalAmountApproved = tableData?.length > 0 ? tableData?.map(data => data?.amount) : [0]
	const amountTotal = sumData(totalAmountApproved)
	const xslcdata =
		tableData?.length > 0
			? tableData?.map((loan, idx) => {
					return {
						sn: idx + 1,
						name: `${loan?.bank_account?.account_name.toUpperCase()}`,
						phone: `'${loan?.applicant?.phone}`,
						institution: loan?.institution?.name ? loan?.institution?.name?.toUpperCase() : "",
						level: loan?.institution?.current_study_year ? loan?.institution?.current_study_year?.toUpperCase() : "",
						course: loan?.institution?.course ? loan?.institution?.course?.toUpperCase() : "",
						amount: loan?.amount ? `${CommaNumber(loan?.amount)}.00` : `0.00`,
						insurance: `${CommaNumber((1 / 100) * loan?.amount)}.00`,
						net_amount: `${CommaNumber(loan?.amount - (1 / 100) * loan?.amount)}.00`,
						comment: loan?.request_status ? loan?.request_status.toUpperCase() : "",
						bank: loan?.bank_account?.bank_name ? loan?.bank_account?.bank_name?.toUpperCase() : "",
						account_number: `'${loan?.bank_account?.account_number}`,
						file_id: `${loan?.id?.toUpperCase()}`,
						age: calculate_age(loan?.applicant?.dob),
						guarantor_one: `${loan?.guarantor_one?.surname?.toUpperCase()} ${loan?.guarantor_one?.first_name?.toUpperCase()} ${loan?.guarantor_one?.middle_name?.toUpperCase()}`,
						guarantor_one_phone: `'${loan?.guarantor_one?.phone} `,
						guarantor_one_work_place: `${loan?.guarantor_one?.work_place}`,
						guarantor_two: `${loan?.guarantor_two?.surname?.toUpperCase()} ${loan?.guarantor_two?.first_name?.toUpperCase()} ${loan?.guarantor_two?.middle_name?.toUpperCase()}`,
						guarantor_two_phone: `'${loan?.guarantor_two?.phone} `,
						guarantor_two_work_place: `${loan?.guarantor_two?.work_place}`,
						disburse_date: loan?.disburse_date ? `${dayjs(loan?.disburse_date).format("MMM D, YYYY")}` : "",
						monthly_repayment_amount: `${CommaNumber(loan?.monthly_repayment_amount)}`,
						repayment_period_starts: loan?.repayment_period_starts ? `${dayjs(loan?.repayment_period_starts).format("MMM D, YYYY")}` : ""
					}
			  })
			: []

	const totalNetAmount = tableData?.length > 0 ? tableData?.map(data => (1 / 100) * data?.amount) : [0]
	const totalMonthlyRepaymentAmount = tableData?.length > 0 ? tableData?.map(data => data?.monthly_repayment_amount) : [0]
	const amountNetTotal = amountTotal - sumData(totalNetAmount)
	const amouninsuranceTotal = sumData(totalNetAmount)
	const monthlyRem = sumData(totalMonthlyRepaymentAmount)
	const hold_data = [
		[
			"S/N",
			"NAME",
			"PHONE",
			"INSTITUTION",
			"LEVEL",
			"COURSE",
			"AMOUNT",
			"INSURANCE",
			"NET AMOUNT",
			"COMMENT",
			"BANK",
			"ACCOUNT NUMBER",
			"FILE ID",
			"AGE",
			"FIRST GUARANTOR",
			"PHONE",
			"WORK PLACE",
			"SECOND GUARANTOR",
			"PHONE",
			"WORK PLACE",
			"DATE DISBURSED",
			"MONTHLY REPAYMENT AMOUNT",
			"MONTHLY REPAYMENT STARTS"
		]
	]
	const emptyrow_data = [["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""]]
	const total_data = [
		[
			"TOTAL",
			"",
			"",
			"",
			"",
			"",
			`${CommaNumber(amountTotal)}.00`,
			`${CommaNumber(amouninsuranceTotal)}.00`,
			`${CommaNumber(amountNetTotal)}.00`,
			"",
			"",
			"",
			"",
			"",
			"",
			"",
			"",
			"",
			"",
			"",
			"",
			monthlyRem ? `${CommaNumber(monthlyRem)}` : "",
			"",
			""
		]
	]
	const mappedArray = xslcdata.length > 0 ? xslcdata?.map(obj => Object.values(obj)) : []
	const concatenatedArr = [].concat(hold_data, mappedArray, emptyrow_data, total_data)


	return (
		<>
			{show && (
				<Loandetails show={show}>
					<Tabledetails payload={selected} close={closeDetails} />
				</Loandetails>
			)}
			<div>
				<div>
					<div className="justify-between flex items-center">
					   <h5 className="font-bold">{title}</h5>
						{filteredLoan?.length > 0 ? (
							<div className="ml-auto">
								{role === "lph" ? (
									<CSVLink filename={`${title?.toLowerCase()}.csv`} data={concatenatedArr}>
										<button className="bg-primary text-white px-6 py-2 font-medium rounded-lg my-3">Download CSV</button>
									</CSVLink>
								) : null}
							</div>
						) : null}
						<span style={{ marginLeft: "10px" }}></span>
					</div>
				</div>
				<div>
					<Table
						columns={[
							{
								title: 'Pick',
								fixed:"left",
								key: 'pick',
								width:100,
								render: (_,loan) =>(
									<CheckBox
										checked={selectedLoans.includes(loan._id)}
										handleCheck={()=>selectLoan(loan._id)}
									/>
								)
							},
							...MEMO_COLUMNS_V2
						]}
						footer={()=>{
							return <div className="font-medium"><b>Total Loan Amount : </b>₦{amountTotal.toLocaleString()}</div>
						}}
						scroll={{x: 1300}}
						dataSource={filteredLoan}
					/>
				</div>
			</div>
		</>
	)
}

export default LoanAppTable
