import LoanDetailsComp from 'components/Dashboard/Loan/LoanDetails'
import DashboardHeader from 'components/Dashboard/shared/DashboardHeader'
import MainLayout from 'layout/MainLayout'
import React from 'react'

const LoanDetailsPage = () => {
  return (
    <MainLayout>
      <DashboardHeader title='Loan Details' back/>
      <LoanDetailsComp />
    </MainLayout>
  )
}

export default LoanDetailsPage
