import React, { useEffect, useState } from 'react'
import ModalComp from './ModalComp'
import useAdminData from '../../../../hooks/useAdminData'
import { message } from 'antd'
import { updateLoanAmount } from 'services/LoanService'
import GuarantorTab from './GuarantorTab'
import ActionModal from './ActionModal'
import LPHAcceptBtn from './LPHAcceptBtn'
import ProfileTab from './ProfileTab'
import { InstitutionTab } from './InstitutionTab'
import NextOfKinTab from './NextOfKinTab'
import DocumentsTab from './DocumentsTab'
import AccountDetailsTab from './AccountDetailsTab'
import LoanTabInfo from './LoanInfoTab'
import BvnTab from './BvnTab'
import UserRecentLoans from './UserRecentLoans'
import { stpAvatar } from 'utils/assets'
import { getStatusData, StatusTag } from './StatusTag'
// import ForwardArrow from 'components/icons/forward-arrow'
// import BackArrow from 'components/icons/back-arrow'

const LoanProTab = ({ loan:userLoan, getLoan,recentLoan }) => {

  const loan = {data:userLoan}
  const { adminRole } = useAdminData()
  const loanData = userLoan[0]
  const [tabState, setTabState] = useState(1)
  const [amount, setAmount] = useState("0")
  const [modalOpen, setModalOpen] = useState(false)
  const [loading, setLoading] = useState(false)



  const openModal = () => {
    setModalOpen(true)
  }
  const closeModal = () => {
    setModalOpen(false)
  }
  const activeTab = (index) => {
    setTabState(index)
  }

  useEffect(() => {
    if (loanData) {
      setAmount(loanData.amount.toString())
    }
  }, [loanData])

  async function updateAmount() {

    if (amount.trim().length === 0) {
      return message.error("Please provide a valid loan amount")
    }
    if (parseFloat(amount) < 1000) {
      return message.error("Please provide a valid loan amount")
    }
    setLoading(true)
    const res = await updateLoanAmount({
      amount,
      id: loanData.id
    })
    if (res) {
      await getLoan()
      closeModal()
      message.success("Loan amount updated successfully")
    }
    setLoading(false)
  }

  const tabTitles = [
    "Student Profile",
    "Institution",
    " Next of kin",
    "Documents",
    "Account info",
    " BVN Data",
    "Loan Info",
    "Guarantor Details"
  ]

  const statusData = getStatusData(loanData?.status)

  return (
    <div className="mt-8">
      {/* <div className="flex items-center justify-end gap-x-4">
        <button><ForwardArrow/></button>
        <button><BackArrow/></button>
      </div> */}
      <ActionModal onOk={updateAmount} onClose={closeModal} loading={loading} modalOpen={modalOpen}>
        <p className="text-2xl text-center font-semibold">Update Loan Amount</p>
        <div className="my-2">
          <input type="number" value={amount} onChange={(e) => setAmount(e.target.value)} placeholder="Enter New Loan Amount" className="border p-2 w-full border-gray-100 rounded-lg" />
        </div>
      </ActionModal>

     <div className="p-3 flex gap-3 bg-white rounded-lg">
        <div>
              <img
                className="h-[100px] mb-3 w-[100px] rounded-full object-cover"
                src={
                    loan?.data[0]?.applicant?.avatar
                        ? loan?.data[0]?.applicant?.avatar
                        : `${stpAvatar}`
                }
                alt="student avatar"
            />
          <ModalComp loan={loan} />

        </div>
        <div className="flex-1 text-end">
          <StatusTag statusData={statusData} />
        </div>
     </div>
      <div className="flex gap-4 mt-8">
        <div>
        <div className="w-[250px]">
          {tabTitles.map((title, index) => (
            <button onClick={() => activeTab(index + 1)}
            className={`border-b p-2 w-full text-left px-4 mb-1 cursor-pointer rounded-md ${tabState === index + 1 ?"bg-primary text-white":"bg-white rounded-md"}`}>
                {title}
            </button>
          ))}
        </div>
        </div>
        <section className="flex-1">
          {(adminRole === "lph" || adminRole === "la") && loanData && loanData.lph_status !== "accepted" && <LPHAcceptBtn loan={loanData} refetch={getLoan} />}

          {tabState === 1 && (<ProfileTab loan={loan}/>)}

          {tabState === 2 && (<InstitutionTab loan={loan}/>)}

          {tabState === 3 && (<NextOfKinTab loan={loan}/>)}

          {tabState === 4 && (<DocumentsTab loan={loan}/>)}

          {tabState === 5 && (<AccountDetailsTab loan={loan}/>)}

          {tabState === 6 && (<BvnTab loan={loan}/>)}

          {tabState === 7 && (
            <LoanTabInfo
              loan={loan}
              loanData={loanData}
              openModal={openModal}
              adminRole={adminRole}
            />
          )}

          {tabState === 8 && (<GuarantorTab loan={loanData} />)}

        </section>
      </div>
      <UserRecentLoans recentLoans={recentLoan}/>
    </div>
  )
}

export default LoanProTab
