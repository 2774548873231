import MemoDetails from 'components/Dashboard/Memo/MemoDetails'
import DashboardHeader from 'components/Dashboard/shared/DashboardHeader'
import MainLayout from 'layout/MainLayout'
import React from 'react'

const MemoDetailsPage = () => {
  return (
    <MainLayout>
      <DashboardHeader title="Memo Details" back/>
      <MemoDetails />
    </MainLayout>
  )
}

export default MemoDetailsPage
