import MainLayout from 'layout/MainLayout'
import React from 'react'
import ReappTab from 'components/Dashboard/ReappMemo/ReappTab'
import DashboardHeader from 'components/Dashboard/shared/DashboardHeader'

const ReApplicantPage = () => {
  return (
    <MainLayout>
      <DashboardHeader title="Re-applicant" back/>
      <div className="mt-8">
        <ReappTab />
      </div>
    </MainLayout>
  )
}

export default ReApplicantPage
