import { useFormik } from "formik"
import * as Yup from "yup"
import React, { useMemo, useState } from "react"
import { createMemo, memoServiceEs, memoServiceOthers, useAdminData } from "services/AdminUserService"
import authStore from "mobx/AuthStore"
import { BASE_URL } from "services"
import http from "services/httpService"
import { useQuery } from "react-query"
import FullScreenLoader from "../FullScreenLoader"
import { useParams } from "react-router-dom"
import { toast } from "react-hot-toast"
import { observer } from "mobx-react-lite"
import { Modal } from "antd"
import checkboxStore from "mobx/CheckboxStore"

function SendMemoComp({ user, disabled, title = "Send Memo" }) {
	const [confirmationOpen,setConfirmationOpen] = useState(false)
	const [sendMemo, setSendMemo] = useState(false)
	const params = useParams()
	const url = `${BASE_URL}/admin/who_i_am`
	const fetcher = async () => {
		const res = await http.get(url)
		return res?.data?.data
	}

	// console.log("Paeramssnn||||", params)
	const { data: userProfile } = useQuery("profile", fetcher)
	const onSuccess = () => {
		// console.log("admin data fetched successfully", adminData?.data?.data)
	}
	const onError = () => {
		// console.log("There is an error here", error)
	}

	const { isLoading, data: adminData } = useAdminData(onSuccess, onError)

	const toggleSendMemo = () => {
		if (disabled) return toast.error("Loan (s) under this table has been disbursed, you cannot send memo anymore")
		setSendMemo(!sendMemo)
	}

	const handleSendMemo = async()=>{

		const payload = { ...formik.values }
		payload.id = params?.id ? params.id : ""
		payload.memoId = params?.threadId ? params.threadId : ""

		if (userProfile?.admin_role === "es") {
			const res = await memoServiceEs(payload)
			if (!res.error) {
				formik.resetForm()
				toggleSendMemo()
				setConfirmationOpen(false)
			}
		}

		if (userProfile?.admin_role === "lph") {
			const res = await createMemo(payload)
			if (!res.error) {
				formik.resetForm()
				toggleSendMemo()
				setConfirmationOpen(false)
			}
		}

		if (userProfile?.admin_role === "la") {
			const res = await memoServiceOthers(payload)
			if (!res.error) {
				formik.resetForm()
				toggleSendMemo()
				setConfirmationOpen(false)
			}
		}
	}

	const data = useMemo(() => {
		const allAdmin = adminData?.data?.data ? adminData?.data?.data : []
		const adminFilter = allAdmin?.filter(admin => admin.first_name && (admin.admin_role === "es" || admin.admin_role === "la"))
		const adminFilterEs = allAdmin?.filter(admin => admin.first_name && (admin.admin_role === "acc" || admin.admin_role === "lph"))
		const adminFilterLa = allAdmin?.filter(admin => admin.first_name && admin.admin_role === "lph")
		return { adminFilter, adminFilterLa, adminFilterEs }
	}, [adminData?.data?.data])

	const formik = useFormik({
		enableReinitialize: false,
		initialValues: {
			status: "approved",
			to_admin: "",
			note: ""
		},
		onSubmit: async () => {
			setConfirmationOpen(true)
		},
		validationSchema: Yup.object({
			note: Yup.string().required().label("Messaage"),
			to_admin: Yup.string().required().label("Send To:"),
			status: Yup.string().required().label("Status")
		})
	})


	const toAdmin = userProfile?.admin_role === "es" ? data.adminFilterEs : userProfile?.admin_role === "la" ? data.adminFilterLa : userProfile?.admin_role === "lph" ? data?.adminFilter : []
	if (isLoading) return <FullScreenLoader show={true} />
	return (
		<>
		<Modal footer={null} open={confirmationOpen} onCancel={()=>setConfirmationOpen(false)} centered>
			<div className="p-6 text-center">
				<p className="font-bold text-3xl">Confirm Memo Submission</p>
				<p className="my-4 text-base">Are you sure you want to submit this memo with <b> {checkboxStore.selectedValues.length} selected loans</b>, this process cannot be reversed</p>
				<footer className="flex items-center justify-center gap-x-8">
					<button onClick={()=>setConfirmationOpen(false)} className="text-white flex-1 text-base w-full bg-red-400 px-6 py-3 rounded-md" type="button">
						Cancel
					</button>
					<button onClick={handleSendMemo} className="text-white bg-primary text-base flex-1 w-full px-6 py-3 rounded-md" disabled={authStore.isLoading} type="submit">
						{authStore.isLoading ? "Sending memo..." : "Proceed"}
					</button>
				</footer>
			</div>
		</Modal>
		<div>
			{/* <Div width="100%" display="flex" justify="flex-end"> */}
			<button className="text-white px-6 py-2 rounded-md bg-primary" onClick={() => toggleSendMemo()}>{title}</button>
			{/* </Div> */}

			<Modal centered footer={null} open={sendMemo} onCancel={() => setSendMemo(false)}>
				<div className="py-4">
					<h1 className="text-xl font-semibold mb-6">Send Memo</h1>
					<div>
						<form className="modal-content" onSubmit={formik.handleSubmit}>
							<label className="block font-medium mb-1">Send To</label>
							<select
								className="w-full bg-gray-200 px-4 text-base py-2 border-0 rounded-md"
								name="to_admin"
								id="to_admin"
								value={formik.values.to_admin}
								onBlur={formik.handleBlur}
								onChange={formik.handleChange}
								visible={formik.touched.to_admin}
								width="250px"
							>
								<option value="">Select receiver</option>
								{toAdmin?.map(adminuser => (
									<option value={adminuser._id}>
										{adminuser?.surname} {adminuser?.first_name} -{" "}
										{adminuser.admin_role === "es"
											? "Executive Secretary"
											: adminuser?.admin_role === "lo"
												? "Loan Officer"
												: adminuser?.admin_role === "lph"
													? "Loan Processing Head"
													: adminuser?.admin_role === "la"
														? "Auditor"
														: adminuser?.admin_role === "fdo"
															? "Front Desk Officer"
															: adminuser?.admin_role === "blog"
																? "Blogger"
																: adminuser?.admin_role === "acc"
																	? "Accountant"
																	: "NIL"}
									</option>
								))}
							</select>
							<p className="text-red-500 mb-6 text-sm">{formik.errors.to_admin}</p>
							<label className="block font-medium mb-1 mt-8">Memo Message</label>
							<textarea name="note" id="note" className="h-[100px] w-full bg-gray-200 p-3 text-base rounded-md" placeholder="Enter Message" value={formik.values.note} onBlur={formik.handleBlur} onChange={formik.handleChange} visible={formik.touched.note} />
							<p className="text-red-600 mb-7">{formik.errors.note}</p>
							<footer className="flex items-center justify-center gap-x-8">
								<button className="text-white bg-red-400 px-6 py-2 rounded-md" onClick={()=>setSendMemo(false)} type="button">
									Cancel
								</button>
								<button className="text-white bg-primary px-6 py-2 rounded-md" disabled={authStore.isLoading} type="submit">
									{authStore.isLoading ? "Sending memo..." : "Proceed"}
								</button>
							</footer>
						</form>
					</div>
				</div>
			</Modal>
		</div>
		</>
	)
}

export default observer(SendMemoComp)
