import { Link } from "react-router-dom"
import { DASHBOARD_LOAN_DETAILS } from "routes"
import CommaNumber from "comma-number"
import ConfirmAccept, { ConfirmReferLoan } from "../RefferedLoans/ConfirmAccept"
import { reviewLoanByLO } from "services/LoanService"
import { StatusTag } from "../LoanDetails/StatusTag"
// import { StatusDiv } from "components/Dashboard/Home/RecentApplications/style"
// import { ByshelbP } from "globalStyles"

export const PENCOLUMNS = [
	{
		Header: "Applicant",
		accessor: (originalRow, rowIndex) => `${originalRow?.applicant?.first_name} ${originalRow?.applicant?.surname}`
	},
	{
		Header: "LGA",
		accessor: (originalRow, rowIndex) => originalRow?.applicant?.lga
	},
	{
		Header: "Institution",
		accessor: (originalRow, rowIndex) => <div style={{ maxWidth: "250px" }}>{originalRow?.institution?.name}</div>
	},
	{
		Header: "Amount",
		accessor: (originalRow, rowIndex) => `₦${CommaNumber(originalRow?.amount)}`
	},
	{
		Header: "Loan Type",
		accessor: "loan_type"
	},
	{
		Header: "View Details",
		accessor: (originalRow, rowIndex) => originalRow?.status && <Link to={`${DASHBOARD_LOAN_DETAILS}/${originalRow.id}`}>View</Link>
	}
]
export const PENCOLUMNS_V2 = [
	{
		title: "Applicant",
		key: "applicant",
		fixed:"left",
		width:150,
		render: (_,originalRow) => `${originalRow?.applicant?.first_name} ${originalRow?.applicant?.surname}`
	},
	{
		title: "LGA",
		key: "lga",
		width:150,
		render: (_,originalRow) => originalRow?.applicant?.lga
	},
	{
		title: "Institution",
		key: "Institution",
		width:150,
		render: (_,originalRow) => <div style={{ maxWidth: "250px" }}>{originalRow?.institution?.name}</div>
	},
	{
		title: "Amount",
		key: "amount",
		width:100,
		render: (_,originalRow) => `₦${CommaNumber(originalRow?.amount)}`
	},
	{
		title: "Loan Type",
		dataIndex: "loan_type",
		key: "loan_type",
		width:150,
	},
	{
		title: "View Details",
		key: "details",
		fixed:"right",
		width:100,
		render: (_,originalRow) => originalRow?.status && <Link to={`${DASHBOARD_LOAN_DETAILS}/${originalRow.id}`}>
			<button className="px-6 py-1 justify-center font-semibold rounded-full border border-secondary-300 bg-secondary-50 text-secondary-700">View</button>
		</Link>
	}
]
export const PENCOLUMNS_V3 = [
	{
		title: 'Revised as Requested',
		key: 'revison_requested',
		fixed:"left",
		width:200,
		render: (_,originalRow) => {
			if(originalRow.edit_requested) return (
				<p className="text-center font-medium">
					{originalRow.edit_request_responded ? "Yes" :"No"}
				</p>
			)
			return <p className="text-center">N/A</p>
		}
	},
	{
		title: "Applicant",
		key: "applicant",
		fixed:"left",
		width:150,
		render: (_,originalRow) => `${originalRow?.applicant?.first_name} ${originalRow?.applicant?.surname}`
	},
	{
		title: "LGA",
		key: "lga",
		width:150,
		render: (_,originalRow) => originalRow?.applicant?.lga
	},
	{
		title: "Institution",
		key: "Institution",
		width:150,
		render: (_,originalRow) => <div style={{ maxWidth: "250px" }}>{originalRow?.institution?.name}</div>
	},
	{
		title: "Amount",
		key: "amount",
		width:100,
		render: (_,originalRow) => `₦${CommaNumber(originalRow?.amount)}`
	},
	{
		title: "Loan Type",
		dataIndex: "loan_type",
		key: "loan_type",
		width:150,
	},
	{
		title: "View Details",
		key: "details",
		fixed:"right",
		width:150,
		render: (_,originalRow) => originalRow?.status && <Link to={`${DASHBOARD_LOAN_DETAILS}/${originalRow.id}`}>
			<button className="px-6 py-1 justify-center font-semibold rounded-full border border-secondary-300 bg-secondary-50 text-secondary-700">View</button>
		</Link>
	}
]

export const USER_RECENT_LOANS = [
	{
		title: "Institution",
		key: "Institution",
		width:150,
		render: (_,originalRow) => <div style={{ maxWidth: "250px" }}>{originalRow?.institution?.name}</div>
	},
	{
		title: "Amount",
		key: "amount",
		width:100,
		render: (_,originalRow) => `₦${CommaNumber(originalRow?.amount)}`
	},
	{
		title: "Status",
		key: "status",
		width:100,
		render: (_,originalRow) => <StatusTag status={originalRow?.status} />,
	},
	{
		title: "Loan Type",
		dataIndex: "loan_type",
		key: "loan_type",
		width:150,
	},
]

export function getPendingColumns(refetch,isEditRequestData=false){
	return isEditRequestData ? [
		...PENCOLUMNS_V3,
		{
			title: "Action",
			key:"details",
			fixed:"right",
			width:100,
			render: (_,originalRow,) => (
				<ConfirmAccept
					title={"Accept Changes"}
					actionHandler={()=>reviewLoanByLO(originalRow.id)}
					refetch={refetch}
				/>
			)
		}
	] :
	[...PENCOLUMNS_V2,
		{
		title: "Action",
		key:"details",
		fixed:"right",
		width:100,
		render: (_,originalRow,) => (
			<ConfirmReferLoan
				refetch={refetch}
				loan={originalRow}
			/>
		)
	}]
}