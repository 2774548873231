export function getStatusData(status) {
  if (status === 'approved') return { color: '#5caa77', text: 'Approved' }
  if (status === 'rejected') return { color: '#ef9a9a', text: 'Rejected' }
  if (status === 'pending') return { color: '#fff59d', text: 'Pending' }
  if (status === 'disbursed') return { color: '#5caa77', text: 'Disbursed' }
  if (status === 'reviewing') return { color: '#9E9E9E', text: 'Reviewing' }
  return { color: '#e23644', text: 'Rejected' }
}

export function StatusTag({ statusData,status }) {

  const currentStatus = statusData? statusData : getStatusData(status)

  const style = {
    button: {
      padding: '8px 32px',
      background: currentStatus.color,
      borderRadius: '8px',
      border: 'none',
      fontWeight: 500,
    },
    span: {
      textTransform: 'capitalize',
    },
  }
  return (
    <button style={style.button}>
      <span style={style.span}>{currentStatus.text}</span>
    </button>
  )
}