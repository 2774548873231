import DashboardHeader from "components/Dashboard/shared/DashboardHeader";
import ArrowRange from "components/icons/arrow-range";
import CloseIcon from "components/icons/close";
import MainLayout from "layout/MainLayout";
import { DateTime } from "luxon";
import { useState } from "react";

const getFormattedDate = (date)=>{
    if(date instanceof Date){
        return {
            date: date.getDate(),
            day: date.getDay(),
            year: date.getFullYear(),
            month: date.getMonth() + 1,
        }
    }
}

function SelectDatesField() {


    const [dateRanges,setDateRanges] = useState(()=>{
        const startDate = DateTime.now().minus({years:20}).toJSDate();
        const curentDate = DateTime.now().toJSDate();

        return [startDate, curentDate];
    })

    const startDate = getFormattedDate(dateRanges[0])
    const endDate = getFormattedDate(dateRanges[1])



    return (
        <div>
            <p className="text-[#575757] mb-3">Filter by date</p>
            <div className="bg-white p-2 px-4 rounded-3xl flex items-center min-h-[57px]">
                <div className="">
                    <p className="font-bold">From</p>
                    <p>{startDate.date}/{startDate.month}/{startDate.year}</p>
                </div>
                <div className="mx-auto text-center">
                    <ArrowRange/>
                </div>
                <div className="">
                    <p className="font-bold">To</p>
                    <p>{endDate.date}/{endDate.month}/{endDate.year}</p>
                </div>
                <button className="ml-auto" ><CloseIcon /></button>
            </div>
        </div>
    )
}

export default function ShareLoanDataPage() {

    return (
        <MainLayout>
            <main>
                <DashboardHeader back title={"Share to mail"} />
                <section className="mt-12">
                    <div className="max-w-[502px]">
                        <p><i>The filter automatically selects all data from first date to present, select custom dates or cancel to send all data.</i></p>
                        <form className="mt-10 max-w-[342px]">
                            <SelectDatesField/>
                            <div className="mt-[46px] max-w-[300px]">
                                <p className="mb-1">Email</p>
                                <input placeholder="Enter Email Here" type="text" className="placeholder: py-[14px] w-full px-4 bg-white rounded-[10px]"/>
                            </div>
                            <div className="flex mt-[45px] max-w-[164px] text-xs font-bold gap-x-3">
                                <button type="submit" className="bg-[#140D47] flex items-center justify-center rounded-[10px] flex-1 text-white h-11">
                                    <span>Send To Mail</span>
                                </button>
                            </div>
                        </form>
                    </div>
                </section>
            </main>
        </MainLayout>
    )
}