import React from "react"
import { Link } from "react-router-dom"
import { DASHBOARD_LOAN_ROUTE } from "routes"
import RecentItemLoan from "./RecentItem"
import { useQuery } from "react-query"
import { getRecentLoans } from "services/LoanService"
import SectionLoader from "components/SectionLoader"
import { EmptyApplications } from "utils/assets"
import ForwardArrow from "components/icons/forward-arrow"

function EmptyApplicationsPlaceholder() {
	return (
		<div className="text-center text-sm">
			<img src={EmptyApplications} className="mx-auto" alt="empty applications" />
			<p className="text-[#666287] my-2 italic">No current applications <i></i></p>
			<p className="text-[#3C3C3C]">New applications will appear here </p>
		</div>
	)
}

function RecentApplications() {
	const { data, isLoading } = useQuery("recent-loans", getRecentLoans)

	return (
		<div>
			{isLoading && <SectionLoader />}
			<header className="flex items-center justify-between mb-4">
				<h4 className="text-lg text-[#3C3C3C]">Recent Applications</h4>
				<Link to={DASHBOARD_LOAN_ROUTE}>
					<button className="text-[#140D47] font-bold flex items-center gap-x-1 text-lg">
						<span>View All</span>
						<ForwardArrow/>
					</button>
				</Link>
			</header>
			<div className="bg-white h-[260px] rounded-2xl py-4 overflow-y-auto">
				{data?.data?.length > 0
					? data?.data?.map(loan => (<RecentItemLoan key={loan?._id} loan={loan}/>))
					: (<EmptyApplicationsPlaceholder />)
				}
			</div>
		</div>
	)
}

export default RecentApplications
