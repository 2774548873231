import MemoComp from 'components/Dashboard/Memo'
import DashboardHeader from 'components/Dashboard/shared/DashboardHeader'
import MainLayout from 'layout/MainLayout'
import React from 'react'

const MemoPage = () => {
  return (
    <MainLayout>
      <DashboardHeader back title="Memos"/>
      <MemoComp />
    </MainLayout>
  )
}

export default MemoPage
