import styled from "styled-components/macro"
import Colors from "utils/colors"
import media from "utils/media"

export const ProfileContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	padding: 3rem 3rem;
	${media.tablet`
	width: 100%;
	padding: 0rem 20px;
	flex-direction: column;
	`}
	${media.mobile`
	width: 100%;
	padding: 0rem 20px;
	flex-direction: column;
  justify-content:center;
	`}
`
export const ProfileDiv = styled.div`
	width: 90%;
	height: 250px;
	display: flex;
	align-items: center;
	background: #d9d9d9;
	border-radius: 30px;
	margin: 0 auto;
	padding: 20px;
	/* margin-top: 4rem; */
`
export const ProfileDivContainer = styled.div`
	width: 90%;
	display: flex;
	flex-direction: column;

	background: #d9d9d9;
	border-radius: 30px;
	margin: 0 auto;
	padding: 20px;
`
export const ProfileUserName = styled.h2`
	font-family: "Tahoma";
	font-style: normal;
	font-weight: 700;
	font-size: 35px;
	line-height: 35px;
	letter-spacing: -0.02em;
	color: #6d0062;
	margin-bottom: 2px;
`
export const ProfileLable = styled.p`
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 14px;
	letter-spacing: 0.025em;
	color: #1e1e1e;
	margin-top: 20px;
	margin-bottom: -15px;
`
export const ProfileRightDiv = styled.div`
	margin-left: 20px;
`

export const FormContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
`
export const ProLeft = styled.div`
	width: 45%;
	display: flex;
	flex-direction: column;
`
export const ProRight = styled.div`
	width: 45%;
	display: flex;
	flex-direction: column;
`
export const UploadSelect = styled.button`
	width: 100%;
	margin-top: 3rem;
	height: 35px;
	border: 2px solid #d7d7d7;
	border-radius: 15px;
`
export const ProDiv = styled.form`
	width: calc(84.9% - 30px);
	display: flex;
	margin: 0 auto;
	margin-left: 75px;
	flex-direction: column;
	align-items: center;
	margin-bottom: 6rem;
`
export const SubmitButton = styled.button`
	width: 200px;
	height: 42px;
	background: linear-gradient(73.6deg, rgba(57, 26, 96, 0.97) 4.94%, rgba(109, 0, 98, 0.97) 71.35%);
	border-radius: 10px;
	color: ${Colors.white};
	margin-top: 2rem;
	margin-bottom: 2rem;
	cursor: pointer;
`

export const DpImageDiv = styled.div`
	width: 140px;
	height: 140px;
	border-radius: 50%;
	position: relative;

	img {
		width: 140px;
		height: 140px;
		border-radius: 50%;
		object-fit: cover;
	}
`

export const PickIcon = styled.span`
	position: absolute;
	right: 4%;
	bottom: 10%;
	z-index: 1000;
	cursor: pointer;
`
