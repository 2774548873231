export default function BackArrow(){
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="mask0_134_9062" style={{"maskType":"alpha"}} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
        <rect width="24" height="24" transform="matrix(-1 0 0 1 24 0)" fill="#D9D9D9"/>
        </mask>
        <g mask="url(#mask0_134_9062)">
        <path d="M10 18L11.4 16.55L7.85 13H20V11H7.85L11.4 7.45L10 6L4 12L10 18Z" fill="#140D47"/>
        </g>
        </svg>
        
    )
}