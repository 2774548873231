import { DataField } from './DataField'



export default function LoanTabInfo({loan,loanData,adminRole,openModal}){

    return (
        <div className="grid grid-cols-2 gap-4">
            <DataField
             label={"Loan Comment"}
             value={loan?.data[0]?.comment || 'No Message available'}
            />
            <DataField
             label={"Reffered By"}
             value={ loanData.refferedBy || "Not Reffered Yet"}
            /> 
            <DataField
             label={"Rejected By"}
             value={ <p>
                {loanData.rejectedBy} -{' '}
                <b>{loanData.rejected_by_email}</b>
              </p>}
            /> 
            <DataField
             label={"Application Date"}
             value={new Date(loanData.created_at).toUTCString()}
            />
            <DataField
             label={"Loan Amount"}
             value={
                <>
                    <p>₦{loanData?.amount.toLocaleString()}</p>
                    {(loanData.status === "pending" || loanData.status === "reviewing") &&
                    (adminRole === "lph" || adminRole === "es") && (
                        <button onClick={openModal} className="bg-primary-100 text-primary-800 border px-4 border-primary-400 rounded-md py-2 text-xs">Update Amount</button>
                    )}
                </>
             }
            /> 
            <DataField
             label={"Loan Tenure"}
             value={loanData?.loan_tenure
                ? `${loanData?.loan_tenure} months`
                : 'Not Provided'}
            />
            <DataField
             label={"Loan Repayment Amount"}
             value={loanData?.loan_tenure
                ? `₦${loanData?.monthly_repayment_amount.toLocaleString()}`
                : 'Not Provided'}
            />
            </div>
    )
}