import React from "react"
import * as Yup from "yup"
import { useQueryClient } from "react-query"
import CustomSelectMain from "components/SelectInput"
import TextInput from "components/TextInput"
import { useFormik } from "formik"
import lgaData from "./lgaData"
import { FormContainer, ProDiv, ProLeft, ProRight, SubmitButton } from "./style"
import { updateProfileDetails } from "services/UserService"
import { observer } from "mobx-react-lite"
import authStore from "mobx/AuthStore"

const AdminForm = ({ user }) => {
	const queryClient = useQueryClient()
	// const [proImage, setProImage] = useState(null)
	// const [lgaImage, setLgaImage] = useState(null)
	// const [lgaDoc, setLgaDoc] = useState("")
	// const [prodp, setProdp] = useState("")
	// const ProfileRef = useRef()
	// const ProfileLgaRef = useRef()
	const formik = useFormik({
		enableReinitialize: true,
		initialValues: {
			first_name: user?.first_name ? user?.first_name : "",
			middle_name: user?.middle_name ? user?.middle_name : "",
			surname: user?.surname ? user?.surname : "",
			email: user?.email ? user?.email : "",
			hometown: user?.hometown ? user?.hometown : "",
			phone: user?.phone ? user?.phone : "",
			address: user?.address ? user?.address : "",
			lga: user?.lga ? user?.lga : "",
			gender: user?.gender ? user?.gender : ""
		},
		onSubmit: async (data, helpers) => {
			// authStore.loading()
			const payload = { ...data }
			// if (!lgaDoc) return toast.error("please upload LGA ID")
			// if (!prodp) return toast.error("please upload passport photo")
			// payload.avatar = prodp
			// payload.lga_certificate = lgaDoc
			const response = await updateProfileDetails(payload)
			if (!response.error) {
				queryClient.invalidateQueries("profile")
			}
			if (response.error) {
			}
		},
		validationSchema: Yup.object({
			first_name: Yup.string().max(50).required().label("First Name"),
			middle_name: Yup.string().max(50).required().label("Middle Name"),
			surname: Yup.string().max(50).required().label("Surname"),
			address: Yup.string().max(150).required().label("Address"),
			lga: Yup.string().max(100).required().label("LGA"),
			email: Yup.string().max(255).required().label("Email"),
			hometown: Yup.string().max(100).required().label("Hometown"),
			gender: Yup.string().max(100).required().label("Gender"),
			phone: Yup.string().min(11).max(16).required("Phone Number is required.").label("Phone Number")
		})
	})

	// async function uploadAvatar(file) {
	// 	const formData = new FormData()
	// 	formData.append("image", file[0])

	// 	const response = await miscUplaod(formData)

	// 	if (!response.error) {
	// 		// setProImage(null)
	// 		setProdp(response?.data)
	// 	}
	// 	if (response.error) {
	// 		// setProImage(null)
	// 	}
	// }

	// function handlePickImage() {
	// 	ProfileRef.current.click()
	// }

	// const handleSubmitDp = e => {
	// 	// setProImage(e.target.files[0])
	// 	uploadAvatar(e.target.files)
	// }

	// async function uploadLga(file) {
	// 	const formData = new FormData()
	// 	formData.append("image", file[0])

	// 	const response = await miscUplaod(formData)

	// 	if (!response.error) {
	// 		// setLgaImage(null)
	// 		setLgaDoc(response?.data)
	// 	}
	// 	if (response.error) {
	// 		// setLgaImage(null)
	// 	}
	// }

	// function handlePickImageLga() {
	// 	ProfileLgaRef.current.click()
	// }

	// const handleSubmitLga = e => {
	// 	// setLgaImage(e.target.files[0])
	// 	uploadLga(e.target.files)
	// }
	return (
		<form onSubmit={formik.handleSubmit}>
			<div>
				<div className="grid grid-cols-2">
					{" "}
					<TextInput
						label="First Name"
						name="first_name"
						id="first_name"
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						value={formik.values.first_name}
						error={formik.errors.first_name}
						visible={formik.touched.first_name}
						placeholder="First Name"
						mt="0.5rem"
					/>
					<TextInput
						label="Middle Name"
						name="middle_name"
						id="middle_name"
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						value={formik.values.middle_name}
						error={formik.errors.middle_name}
						visible={formik.touched.middle_name}
						placeholder="Middle Name"
					/>
					<TextInput
						label="Email Address"
						name="email"
						id="email"
						disabled
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						value={formik.values.email}
						error={formik.errors.email}
						visible={formik.touched.email}
						type="email"
						placeholder="Email Address"
					/>
					<TextInput
						label="Phone Number"
						name="phone"
						id="phone"
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						value={formik.values.phone}
						error={formik.errors.phone}
						visible={formik.touched.phone}
						placeholder="Phone Number"
					/>
					<CustomSelectMain
						label={"Gender"}
						name="gender"
						value={formik.values.gender}
						onBlur={formik.handleBlur}
						onChange={formik.handleChange}
						error={formik.errors.gender}
						visible={formik.touched.gender}
					>
						<option style={{ color: "grey" }} value="" disabled>
							Select Gender
						</option>
						{[
							{
								id: "ghhdfsdfd",
								label: "Male",
								value: "male"
							},
							{
								id: "sdfghjkghjd",
								label: "Female",
								value: "female"
							}
						].map(sex => (
							<option className="active-option" key={sex.id} value={sex.value}>
								{sex.label}
							</option>
						))}
					</CustomSelectMain>
				</div>
				<div>
					<TextInput
						label="Surname"
						name="surname"
						id="surname"
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						value={formik.values.surname}
						error={formik.errors.surname}
						visible={formik.touched.surname}
						placeholder="Surname"
					/>
					<TextInput
						label="Home town"
						name="hometown"
						id="hometown"
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						value={formik.values.hometown}
						error={formik.errors.hometown}
						visible={formik.touched.hometown}
						placeholder="Hometown"
					/>

					<CustomSelectMain label={"L.G.A"} name="lga" value={formik.values.lga} onBlur={formik.handleBlur} onChange={formik.handleChange} error={formik.errors.lga} visible={formik.touched.lga}>
						<option style={{ color: "grey" }} value="" disabled>
							---------------
						</option>
						{lgaData?.map(local => (
							<option className="active-option" key={local.id} value={local.value}>
								{local.label}
							</option>
						))}
					</CustomSelectMain>
					<TextInput
						label="Address"
						name="address"
						id="address"
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						value={formik.values.address}
						error={formik.errors.address}
						visible={formik.touched.address}
						placeholder="Enter your address"
					/>

					{/* <UploadSelect type="button" onClick={handlePickImage}>
						Upload Picture
					</UploadSelect> */}

					{/* <input ref={ProfileRef} style={{ display: "none" }} onChange={handleSubmitDp} type="file" accept="image/*" multiple={false} />
					<input ref={ProfileLgaRef} style={{ display: "none" }} onChange={handleSubmitLga} type="file" accept="image/*" multiple={false} /> */}
				</div>
			</div>
			<SubmitButton disabled={authStore.isLoading} type="submit">
				{authStore.isLoading ? "Please wait..." : "Submit"}
			</SubmitButton>
		</form>
	)
}

export default observer(AdminForm)
