import { Modal, Spin } from "antd";

export default function ActionModal({modalOpen,children,onClose,loading,onOk,title}){
    return (
        <Modal open={modalOpen} closable={false} maskClosable={false} closeIcon={false} centered footer={null}>
        {title &&  <p className="text-2xl text-center font-semibold capitalize">{title}</p>}
        {children}
        <div className="flex gap-4 justify-center items-center">
          <button onClick={onClose} className="bg-red-100 text-red-600 border-red-400 rounded-full px-6 py-2">Cancel</button>
          <button disabled={loading} onClick={onOk} className="bg-primary-100 disabled:opacity-60 disabled:cursor-not-allowed text-primary-600 border-primary-400 rounded-full px-6 py-2">
            {
              loading
              ?<Spin/>
              :<span>Continue</span>
            }
          </button>
        </div>
      </Modal>
    )
}
