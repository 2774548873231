import styled from "styled-components/macro"


export const DashboardLayout = styled.div`
width:100%;
height:100vh;


`
export const DashboardBody = styled.div`
overflow-y:scroll;
/* background-color:red; */
`