import DownloadIcon from "components/icons/download";
import { SelectDatesField } from "./SelectDatesField";
import { useDownloadForm } from "./useDownloadForm";



export default function DownloadForm(){

    const {
        handleSubmit,
        startDate,
        endDate,
        loading,
        handleDownloadAll,
        handleSelectDate
    } = useDownloadForm();

    return (
        <section className="mt-12">
        <div className="max-w-[550px]">
            <p><i>The filter automatically selects all data from first date to present, select custom dates or cancel to send all data.</i></p>
            <form className="mt-10 max-w-[430px]" onSubmit={handleSubmit}>
                <SelectDatesField
                    startDate={startDate}
                    endDate={endDate}
                    selectStartDate={(value)=>handleSelectDate(value,0)}
                    selectEndDate={(value)=>handleSelectDate(value,1)}
                />
                <div className="flex mt-[45px] text-xs font-bold gap-x-3">
                    <button disabled={loading} type="submit" className="bg-[#140D47] flex items-center justify-center rounded-[10px] flex-1 text-white h-11">
                        {
                        loading
                        ?<span>Downloading</span>
                        :<span>Download by filter</span>
                        }
                        <span><DownloadIcon/></span>
                    </button>
                    <button onClick={handleDownloadAll} disabled={loading} type="button" className="border rounded-[10px] border-[#140D47] text-[#140D47] flex items-center flex-1 justify-center h-11">
                    {
                        loading
                        ?<span>Downloading</span>
                        :<span>Download All</span>
                        }
                        <span><DownloadIcon/></span>
                    </button>
                </div>
            </form>
        </div>
    </section>
    )
}