import DashboardHeader from "components/Dashboard/shared/DashboardHeader";
import DownloadForm from "components/Download/DownloadForm";
import MainLayout from "layout/MainLayout";


export default function DownloadLoanDataPage() {

    return (
        <MainLayout>
            <main>
                <DashboardHeader back title={"Download file as CSV"} />
                <DownloadForm/>
            </main>
        </MainLayout>
    )
}