import { useEffect, useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"


export const usePaginate = (data=[],perPage=10)=>{

	const router = useNavigate()

	const [currentPage,setCurrentPage] = useState(1)
	const [searchParams] = useSearchParams()
	const pages = data.length > 1 ? Math.ceil(data.length/perPage) : 1



	const activePage = searchParams.get("page")

	const start = (currentPage - 1) * perPage
	const end = start + perPage


	const pageData = data.slice(start,end)

	const setPage = (pageNumber)=>{
			router(`/loan?page=${pageNumber}`)
			setCurrentPage(pageNumber)
	}

	const next = ()=>{
		if(currentPage < pages){
			router(`/loan?page=${currentPage + 1}`)

		}
	}
	const prev = ()=>{
		if(currentPage > 1){
			router(`/loan?page=${currentPage - 1}`)
		}
	}

	useEffect(()=>{
		if(activePage){
			setCurrentPage(parseInt(activePage))
		}
	},[activePage])


	return {
		pageData,
		prev,
		pages,
		currentPage,
		setPage,
		next,
	}
}