import LoanAppTable from "pages/Memo/LoanTable"
import MemoTitle from "./MemoTitle"
import ThreadMessage from "./ThreadMessage"


export default function MemoBody({singleMemo,loans,adminRole,selectedLoans,selectLoan,refetch}) {
    return(
        <div>
        <MemoTitle
            note={singleMemo.note}
            reciever={singleMemo.to_admin.admin_role}
            sender={singleMemo.admin.admin_role}
        />
        <div style={{marginBottom:"80px"}}>
            <div className="pl-8 py-4 border-l border-primary my-auto ">
                {singleMemo.threads.map((thread,index) =>{
                    return <ThreadMessage
                        refetch={refetch}
                        selectedLoans={selectedLoans}
                        thread={thread}
                        singleMemo={singleMemo}
                        isLastMessage={singleMemo.threads.length === index + 1}
                    />
                })}
            </div>
        </div>
        <LoanAppTable
            selectedLoans={selectedLoans}
            selectLoan={selectLoan}
            title="LOAN APPLICATIONS SENT VIA MEMO"
            tableData={loans}
            role={adminRole}
            />
    </div>
    )
}