import FullScreenLoader from "components/Dashboard/FullScreenLoader"
import React from "react"
import { useQuery } from "react-query"
import { useParams } from "react-router-dom"
import { getLoan } from "services/LoanService"

import LoanProTab from "./LoanProTab"

const LoanDetailsComp = () => {
	const params = useParams()
	const { data:response, isLoading,refetch } = useQuery(["loan", params?.id], () => getLoan(params?.id))

	if (isLoading || !response) return <FullScreenLoader />;

	return (
		<div>
			<LoanProTab recentLoan={response.data.recent_loans} loan={response.data.loan} getLoan={refetch} />
		</div>
	)
}

export default LoanDetailsComp
