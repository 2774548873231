import AuthSideNav from "components/AuthNav/SideBar"
import { observer } from "mobx-react-lite"
import React from "react"
import { DashboardLayout } from "./style"
import useAdminData from "hooks/useAdminData"

const Mainlayout = props => {
	const {userProfile} = useAdminData()
	return (
		<div className="bg-[#E7E7ED]">
			<DashboardLayout>
				<AuthSideNav user={userProfile} />
				<div className=" ml-[250px] py-[35px] px-[42px]">
					{props.children}
				</div>
			</DashboardLayout>
		</div>
	)
}

export default observer(Mainlayout)
