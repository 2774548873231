import { DataField } from "./DataField"

export default function ProfileTab({ loan }) {
    const loanData = loan?.data[0]
    const dob = new Date(loanData?.applicant?.dob)

    return (
        <div>
            <div className="">
                <div className="grid grid-cols-2 gap-4">
                    <DataField
                        label={"First Name"}
                        value={loan?.data[0]?.applicant?.first_name
                            ? loan?.data[0]?.applicant?.first_name
                            : 'Not Provided'}
                    />
                    <DataField
                        label={"Middle Name"}
                        value={loan?.data[0]?.applicant?.middle_name
                            ? loan?.data[0]?.applicant?.middle_name
                            : 'Not Provided'}
                    />
                    <DataField
                        label={"Surname"}
                        value={loan?.data[0]?.applicant?.surname
                            ? loan?.data[0]?.applicant?.surname
                            : 'Not Provided'}
                    />
                    <DataField
                        label={"Address"}
                        value={loan?.data[0]?.applicant?.address
                            ? loan?.data[0]?.applicant?.address
                            : 'Not Provided'}
                    />
                    <DataField
                        label={"Email"}
                        value={loan?.data[0]?.applicant?.email
                            ? <span className="lowercase">{loan?.data[0]?.applicant?.email}</span>
                            : 'Not Provided'}
                    />
                    <DataField
                        label={"Phone"}
                        value={loan?.data[0]?.applicant?.phone
                            ? loan?.data[0]?.applicant?.phone
                            : 'Not Provided'}
                    />
                    <DataField
                        label={"LGA"}
                        value={loan?.data[0]?.applicant?.lga
                            ? loan?.data[0]?.applicant?.lga
                            : 'Not Provided'}
                    />
                    <DataField
                        label={"Gender"}
                        value={loan?.data[0]?.applicant?.gender
                            ? loan?.data[0]?.applicant?.gender
                            : 'Not Provided'}
                    />
                    <DataField
                        label={"Employment Status"}
                        value={loan?.data[0]?.applicant?.employment_status
                            ? loan?.data[0]?.applicant?.employment_status
                            : 'Not Provided'}
                    />
                    <DataField
                        label={"Hometown "}
                        value={loan?.data[0]?.applicant?.hometown
                            ? loan?.data[0]?.applicant?.hometown
                            : 'Not Provided'}
                    /><DataField
                        label={"Date of Birth"}
                        value={loan?.data[0]?.applicant?.dob
                            ? `${dob.getDate()}/${dob.getMonth() + 1
                            }/${dob.getFullYear()}`
                            : 'Not Provided'}
                    />
                </div>
            </div>
        </div>
    )
}