import ArrowRange from "components/icons/arrow-range";
import CloseIcon from "components/icons/close";

export function SelectDatesField({startDate,endDate,selectStartDate,selectEndDate}) {

    const handleSelectDate = (action) => {
        return (e) => {
            const value = e.target.value
            if(action){
                action(value)
            }
        }
    }

    return (
        <div>
            <p className="text-[#575757] mb-3">Filter by date</p>
            <div className="bg-white p-2 px-4 rounded-3xl flex gap-x-4 items-center min-h-[57px]">
                <div className="">
                    <p className="font-bold">From</p>
                    <input
                        type="date"
                        value={startDate}
                        className="border-0 outline-0" name="" id="start_date"
                        onChange={handleSelectDate(selectStartDate)}
                    />
                </div>
                <div className="mx-auto text-center">
                    <ArrowRange/>
                </div>
                <div className="">
                    <p className="font-bold">To</p>
                    <input
                        type="date"
                        value={endDate}
                        onChange={handleSelectDate(selectEndDate)}
                        className="border-0 outline-0"
                        name="" id="end_date"
                    />
                </div>
                <button className="ml-auto" ><CloseIcon /></button>
            </div>
        </div>
    )
}
