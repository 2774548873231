export default function ArrowRange(){
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<mask id="mask0_115_8976" style={{"maskType":"alpha"}} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
<rect width="24" height="24" fill="#D9D9D9"/>
</mask>
<g mask="url(#mask0_115_8976)">
<path d="M7 17L2 12L7 7L8.4 8.4L5.825 11H18.175L15.6 8.4L17 7L22 12L17 17L15.6 15.6L18.175 13H5.825L8.4 15.6L7 17Z" fill="#727272"/>
</g>
</svg>
    )
}