import FullScreenLoader from "components/Dashboard/FullScreenLoader"
import React, { useEffect, useMemo, useState } from "react"
import { useQuery } from "react-query"
import {getRefferedLoans, reviewLoanByLPH } from "services/LoanService"
import FilterComp from "../FilterComp"
import { getAppColumns } from "./appColumns"
import SendMemoComp from "../SendMemoComp"
import { observer } from "mobx-react-lite"
import CheckboxAll from "components/Checkbox/checkAll"
import LoanFilter from "components/Dashboard/LoanFIlter"
import { Table } from "antd"
import ConfirmAccept from "./ConfirmAccept"
import { useSearchParams } from "react-router-dom"
import { usePaginate } from "hooks/usePagination"


function FilterHeader({admin_role,handleLoanFilter,handleFilter,filter,loanFilter,globalFilter,setGlobalFilter}) {
	const filterOptions = [
		{value: "all", label: "All Loans",},
		{value: "viewed", label: "Viewed Loans",},
		{value: "not-viewed", label: "Unviewed Loans",},
		{value: "accepted", label: "Accepted Loans",},
	]
	return (
		<div className="flex items-center justify-between gap-8 my-2">
					{
						(admin_role === "lph" || admin_role === "la") && (
							<div className="flex flex-[2] items-center gap-2">
								<button onClick={()=>handleLoanFilter("all")}  className={`py-1 px-4 min-w-[100px] rounded-md ${loanFilter === "all"?"bg-primary text-white":"bg-gray-200 border border-solid border-gray-400"}`}>All</button>
								<button onClick={()=>handleLoanFilter("edit")} className={`py-1 px-4 rounded-md ${loanFilter === "edit"?"bg-primary text-white":"bg-gray-200 border border-solid border-gray-400"}`}>Edit Requested</button>
								<LoanFilter options={filterOptions} filter={filter} handleFilter={handleFilter}/>
							</div>
						)
					}
					<div className="flex-1">
						<FilterComp filter={globalFilter} setFilter={setGlobalFilter} />
					</div>
				</div>
	)
}

const RefferedLoansComp = ({ admin_role, user }) => {
	const [params,setParams] = useSearchParams()
	const [searchQuery,setSearchQuery] = useState("")
	const [filter, setFilter] = useState("all")
	const [loanFilter, setLoanFilter] = useState("all")
	const { data: referredLoans, isLoading,refetch} = useQuery("approved-loans", getRefferedLoans)


	const filterData = (arrData)=>{
		return arrData.filter((loan)=>{
			if(!loan) return true;
			return loan.applicant.first_name.toLowerCase().includes(searchQuery.toLowerCase())
			|| loan.applicant.surname.toLowerCase().includes(searchQuery.toLowerCase())
		})
	}

	const handleSetParams = (_filter,_loanFilter)=>{
		setParams({
			filter:_filter,
			loan_filter:_loanFilter
		})
	}

	const handleFiilter=(_filter)=>{
		// setFilter(_filter)
		handleSetParams(_filter,loanFilter)
	}
	const handleLoanFiilter=(_loanFilter)=>{
		// setLoanFilter(filter)
		handleSetParams(filter,_loanFilter)
	}

	useEffect(()=>{
		if(params){
			const _filter = params.get("filter")
			const _loanFilter = params.get("loan_filter")
			setFilter(_filter || filter)
			setLoanFilter(_loanFilter || loanFilter)
		}
	},[params,loanFilter,filter])


	const columns = useMemo(() => {
		return [
			...getAppColumns(admin_role,loanFilter === "edit"),
			{
				title: "Action",
				key:"details",
				fixed:"right",
				width:100,
				render: (_,originalRow,) => (
					<ConfirmAccept
						actionHandler={()=>reviewLoanByLPH(originalRow.id)}
						refetch={refetch}
					/>
				)
			}
		]
	},[admin_role,loanFilter,refetch])


	const data = useMemo(() => {

		if(!referredLoans) return [];
		if(!referredLoans.data) return [];

		const appLoans = referredLoans?.data.filter(loan=>!loan.in_memo)
		const allLoans = (loanFilter === "all")? appLoans : appLoans.filter(loanItem=>loanItem.edit_requested)

		if(filter === "accepted") return allLoans.filter(loanItem => loanItem.lph_status === "accepted")
		if(filter === "viewed") return allLoans.filter(loanItem => loanItem.lph_status === "viewed")
		if(filter === "not-viewed") return allLoans.filter(loanItem => loanItem.lph_status === "")

		return allLoans


	}, [referredLoans,filter,loanFilter])

	const getLoanStats = ()=>{
		const allLoans = data.map(loan=>loan.amount)
		return {
			total:allLoans.length,
			totalAmount:allLoans.reduce((a,b)=>a+b,0),
		}
	}

	const {pageData,currentPage,setPage,next,prev} = usePaginate(data)

	if (isLoading) return <FullScreenLoader show={true} />

	return (
		<>
			<div>
				<FilterHeader
					loanFilter={loanFilter}
					filter={filter}
					handleFilter={handleFiilter}
					handleLoanFilter={handleLoanFiilter}
					admin_role={admin_role}
					globalFilter={searchQuery}
					setGlobalFilter={setSearchQuery}
				/>
				{	(admin_role === "lph" || admin_role === "la")   && (
						<div className="my-4 flex items-center justify-between gap-2">
						{admin_role === "lph" && <CheckboxAll label="Select All" data={data} />}
						{admin_role === "lph" && <SendMemoComp user={user} />}
					</div>)
				}
				<div className="max-w-[1024px]">
					<Table
						loading={isLoading}
						columns={columns}
						pagination={{
							current:currentPage,
							onChange:(pageNumber)=>{
								setPage(pageNumber)
							},
							pageSize:10,
							nextIcon:()=>{
								return (
									<button onClick={next}> <i className="bi bi-chevron-right"></i></button>
								)
							},
							prevIcon:()=>{
								return (
									<button onClick={prev}> <i className="bi bi-chevron-left"></i></button>
								)
							}
						}}
						dataSource={filterData(pageData)}
						scroll={{x: 1300}}
						footer={()=>{
							const {totalAmount,total} = getLoanStats()
							return (
								<div className="flex items-center  justify-between">
									<p><span className="font-medium">Total Loan Amount</span> : ₦{totalAmount.toLocaleString()}</p>
									<p><span className="font-medium">Total Loan</span> :{total}</p>
								</div>
							);
						}}
					/>
				</div>
			</div>
		</>
	)
}

export default observer(RefferedLoansComp)
