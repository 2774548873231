import { Link } from "react-router-dom"
import { DASHBOARD_LOAN_DETAILS, DASHBOARD_MEMO_DETAILS_ROUTE } from "routes"
import CommaNumber from "comma-number"
import Checkbox from "components/Checkbox"
import dayjs from "dayjs"

export const APPCOLUMNS = [
	{
		Header: "Pick",
		accessor: (originalRow) => <Checkbox value={originalRow?._id} />
	},
	{
		Header: "First Name",
		accessor: (originalRow) => originalRow?.applicant?.first_name + " " +  originalRow?.applicant?.surname
	},
	{
		Header: "LGA",
		accessor: (originalRow,) => originalRow?.applicant?.lga
	},
	{
		Header: "Institution",
		accessor: (originalRow,) => <div style={{ maxWidth: "250px" }}>{originalRow?.institution?.name}</div>
	},
	{
		Header: "Amount",
		accessor: (originalRow,) => `₦${CommaNumber(originalRow?.amount)}`
	},
	{
		Header: "Loan Type",
		accessor: "loan_type"
	},
	{
		Header: "Reffered By",
		accessor: (originalRow,) => originalRow?.reffered_by_name
	},
	{
		Header: "View Details",
		accessor: (originalRow,) => originalRow?.status && <Link to={`${DASHBOARD_LOAN_DETAILS}/${originalRow.id}`}>View</Link>
	}
]
export const APPCOLUMNS_V2 = [
	{
		title: 'Pick',
		fixed:"left",
		key: 'pick',
		width:100,
		render: (_,originalRow) => <Checkbox value={originalRow?._id} />
	},
	{
		title: "Applicant",
		fixed:"left",
		width:150,
		render: (_,originalRow) => originalRow?.applicant?.first_name + " " +  originalRow?.applicant?.surname
	},
	{
		title: "LGA",
		key:"lga",
		width:150,
		render: (_,originalRow,) => originalRow?.applicant?.lga
	},
	{
		title: "Institution",
		key:"institution",
		width:250,
		render: (_,originalRow,) => <div style={{ maxWidth: "250px" }}>{originalRow?.institution?.name}</div>
	},
	{
		title: "Amount",
		key:"amount",
		width:100,
		render: (_,originalRow,) => `₦${CommaNumber(originalRow?.amount)}`
	},
	{
		title: "Loan Type",
		dataIndex: "loan_type",
		width:200,
	},
	{
		title: "Reffered By",
		key:"reffered_by",
		width:150,
		render: (_,originalRow,) => originalRow?.reffered_by_name
	},
	{
		title: "View Details",
		key:"details",
		fixed:"right",
		width:150,
		render: (_,originalRow,) => originalRow?.status && <Link to={`${DASHBOARD_LOAN_DETAILS}/${originalRow.id}`}><button className="px-6 py-1 justify-center font-semibold rounded-full border border-secondary-300 bg-secondary-50 text-secondary-700">View</button></Link>
	},
]

export const MEMO_COLUMNS_V2 = [
	{
		title: "S/N",
		key:"s/n",
		width:250,
		render: (_,originalRow,index) => <p>{index + 1}</p>
	},
	{
		title: "Institution",
		key:"institution",
		width:250,
		render: (_,originalRow,) => <div style={{ maxWidth: "250px" }}>{originalRow?.institution?.name}</div>
	},
	{
		title: "Course Of Study",
		key:"course_of_study",
		width:250,
		render: (_,originalRow,) => <div style={{ maxWidth: "250px" }}>{originalRow?.institution?.course}</div>
	},
	{
		title: "Study Level",
		key:"study_level",
		width:250,
		render: (_,originalRow,) => <div style={{ maxWidth: "250px" }}>{originalRow?.institution?.current_study_year}</div>
	},
	{
		title: "Loan Type",
		dataIndex: "loan_type",
		width:200,
	},
	{
		title: "Account Name",
		key:"account_name",
		width:250,
		render: (_,originalRow,) => <div style={{ maxWidth: "250px" }}>{originalRow?.applicant?.bank_account.account_name}</div>
	},
	{
		title: "Account Number",
		key:"account_number",
		width:250,
		render: (_,originalRow,) => <div style={{ maxWidth: "250px" }}>{originalRow?.applicant?.bank_account.account_number}</div>
	},
	{
		title: "Bank",
		key:"bank",
		width:250,
		render: (_,originalRow,) => <div style={{ maxWidth: "250px" }}>{originalRow?.applicant?.bank_account.bank_name}</div>
	},
	{
		title: "Amount",
		key:"amount",
		width:300,
		render: (_,originalRow,) => `₦${CommaNumber(originalRow?.amount)}`
	},
	// {
	// 	title: "See Preview",
	// 	key:"details",
	// 	fixed:"right",
	// 	width:150,
	// 	render: (_,originalRow,) => originalRow?.status && <Link to={`${DASHBOARD_LOAN_DETAILS}/${originalRow.id}`}><button className="px-6 py-1 justify-center font-semibold rounded-full border border-secondary-300 bg-secondary-50 text-secondary-700">View</button></Link>
	// },
	{
		title: "More",
		key:"more",
		fixed:"right",
		width:150,
		render: (_,originalRow,) => originalRow?.status && <Link to={`${DASHBOARD_LOAN_DETAILS}/${originalRow.id}`}><button className="px-6 py-1 justify-center font-semibold rounded-full border border-secondary-300 bg-secondary-50 text-secondary-700">View</button></Link>
	},
]

const ROLES = {
	"es":"Super Admin",
	"lo":"Loan Officer",
	"lph":"Loan Processing Head",
	"la":"Auditor",
	"fdo":"Front Desk Officer",
	"blog":"Blogger",
	"acc":"Accountant"
}

export const MEMO_COLUMNS = [
	{
		title: 'ID',
		fixed:"left",
		key: 'id',
		width:250,
		render: (_,originalRow) => (
			<p>{originalRow.id}</p>
		)
	},
	{
		title: 'From',
		fixed:"left",
		key: 'from',
		width:250,
		render: (_,originalRow) => (
			<p>{originalRow?.admin?.surname} {originalRow?.admin?.first_name}</p>
		)
	},
	{
		title: 'Date',
		key: 'date',
		width:250,
		render: (_,originalRow) => (
			<p>{dayjs(originalRow?.createdAt).format("MMM D, YYYY HH:MM A")}</p>
		)
	},
	{
		title: 'Email',
		key: 'email',
		width:250,
		render: (_,originalRow) => (
			<p>{originalRow?.admin?.email}</p>
		)
	},
	{
		title: 'Role',
		key: 'role',
		width:250,
		render: (_,originalRow) => (
			<p>{ROLES[originalRow?.admin?.admin_role]}</p>
		)
	},
	{
		title: 'Memo Status',
		key: 'memo_status',
		width:150,
		render: (_,originalRow) => (
			<p className="capitalize">{originalRow?.status}</p>
		)
	},
	{
		title: 'View',
		key: 'view',
		fixed:"right",
		width:150,
		render: (_,originalRow) => (
			 <Link to={`${DASHBOARD_MEMO_DETAILS_ROUTE}/${originalRow.memo_id}`}>
				<button className="px-6 py-2 rounded-full bg-primary-50 text-primary-600 border-primary-400 border">View</button>
			 </Link>
		)
	},
]
export const APPCOLUMNS_V3 = [
	{
		title: 'Revised as Requested',
		key: 'revison_requested',
		fixed:"left",
		width:200,
		render: (_,originalRow) => {
			if(originalRow.edit_requested) return (
				<p className="text-center font-medium">
					{originalRow.edit_request_responded ? "Yes" :"No"}
				</p>
			)
			return <p className="text-center">N/A</p>
		}
	},
	{
		title: "Applicant",
		fixed:"left",
		width:150,
		render: (_,originalRow) => originalRow?.applicant?.first_name + " " +  originalRow?.applicant?.surname
	},
	{
		title: "LGA",
		key:"lga",
		width:150,
		render: (_,originalRow,) => originalRow?.applicant?.lga
	},
	{
		title: "Institution",
		key:"institution",
		width:250,
		render: (_,originalRow,) => <div style={{ maxWidth: "250px" }}>{originalRow?.institution?.name}</div>
	},
	{
		title: "Amount",
		key:"amount",
		width:100,
		render: (_,originalRow,) => `₦${CommaNumber(originalRow?.amount)}`
	},
	{
		title: "Loan Type",
		dataIndex: "loan_type",
		width:200,
	},
	{
		title: "Reffered By",
		key:"reffered_by",
		width:150,
		render: (_,originalRow,) => originalRow?.reffered_by_name
	},
	{
		title: "View Details",
		key:"details",
		fixed:"right",
		width:150,
		render: (_,originalRow,) => originalRow?.status && <Link to={`${DASHBOARD_LOAN_DETAILS}/${originalRow.id}`}><button className="px-6 py-1 justify-center font-semibold rounded-full border border-secondary-300 bg-secondary-50 text-secondary-700">View</button></Link>
	}
]

export function getAppColumns(admin_role,isEditRequestData=false,isMemo=false){
	if(isMemo){
		return APPCOLUMNS_V2.filter((col)=>{
			if(col.title === "Pick") return false;
			return true
		})
	}
	if(isEditRequestData){
		return APPCOLUMNS_V3.filter((col)=>{
			if(col.title === "Pick" && admin_role !== "lph") return false;
			return true
		})
	}
	return APPCOLUMNS_V2.filter((col)=>{
		if(col.title === "Pick" && admin_role !== "lph") return false;
		return true
	})
}
