import { message, Modal, Spin } from "antd";
import { useState } from "react";
import { addLoanAccount } from "services/ReappLoanService";
import * as Yup from 'yup';


  const validationSchema = Yup.object({
        first_name: Yup.string().required('First name is required'),
        surname: Yup.string().required('Surname is required'),
        bvn: Yup.string().length(11, 'BVN must be 11 digits').required('BVN is required'),
        dob: Yup.date().required('Date of Birth is required'),
        phone: Yup.string().length(11, 'Phone number must be 11 digits').required('Phone number is required'),
        email: Yup.string().email('Invalid email format').required('Email is required'),
    });

function useReapplicantForm(){
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [formData,setFormData] = useState({
        email: "",
        surname: "",
        first_name :"",
        bvn:"",
        dob:"",
        phone:"",

    });
    const handleInput = (e)=>{
        setFormData(prev=>{
            return {
                ...prev,
                [e.target.name] : e.target.value,
            }
        })
    }

    const validateForm = async()=>{
        try {
           await validationSchema.validate(formData);
            return true
        } catch (error) {
            return false
        }
    }

    const handleSubmit = async(e)=>{
        e.preventDefault()
        const isFormValid = validateForm()
        if(!isFormValid){
            return;
        }
        try {
            setIsSubmitting(true)
            const response = await addLoanAccount(formData)
            if(!response.data){
                message.error(response.error || response.message || response.error_message || "Could not add loan account")
            }
        } finally{
            setIsSubmitting(false)
        }
    }

    return {
        formData,
        handleInput,
        handleSubmit,
        isSubmitting,
    }
}


export default function ReapplicantForm({open,closeModal}){
    

    const {formData,handleInput,handleSubmit,isSubmitting} = useReapplicantForm();

    return (
        <Modal centered open={open} onCancel={closeModal} footer={null}>
        <div className="my-6">
          <p className="text-xl font-medium">Re-Applicant Form</p>
          <form className="mt-4" onSubmit={handleSubmit}>

           <div className="my-2">
            <p>First Name</p>
            <input
                name="first_name"
                value={formData.first_name}
                minLength={3}
                required
                onChange={handleInput}
                type="text"
                className="px-4 py-2 w-full rounded-md bg-gray-300"
            />
           </div>

           <div className="my-2">
            <p>Surname</p>
            <input
                name="surname"
                value={formData.surname}
                minLength={3}
                onChange={handleInput}
                required
                type="text"
                className="px-4 py-2 w-full rounded-md bg-gray-300"
            />
           </div>


           <div className="my-2">
            <p>Email</p>
            <input
                name="email"
                value={formData.email}
                required
                onChange={handleInput}
                type="email"
                className="px-4 py-2 w-full rounded-md bg-gray-300"
            />
           </div>

           <div className="my-2">
            <p>Phone Number</p>
            <input
                name="phone"
                value={formData.phone}
                onChange={handleInput}
                minLength={9}
                maxLength={11}
                required
                type="text"
                className="px-4 py-2 w-full rounded-md bg-gray-300"
            />
           </div>
    
           <div className="my-2">
            <p>BVN</p>
            <input
                name="bvn"
                minLength={11}
                maxLength={11}
                value={formData.bvn}
                onChange={handleInput}
                type="text"
                className="px-4 py-2 w-full rounded-md bg-gray-300"
            />
           </div>

           <div className="my-2">
            <p>Date of Birth</p>
            <input
                name="dob"
                value={formData.dob}
                onChange={handleInput}
                type="date"
                className="px-4 py-2 w-full rounded-md bg-gray-300"
            />
           </div>

           <button disabled={isSubmitting} className="px-6 bg-primary py-2 rounded-md text-lg text-white mt-2 min-w-[250px]">
              {isSubmitting?<><Spin/></>:<span>Submit</span>}
           </button>
          </form>
        </div>
      </Modal>
    )
}