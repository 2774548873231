import React, { useState } from "react"
import RejLoansComp from "../Loan/RejectedLoans"
import RefferedLoans from "../Loan/RefferedLoans"
import DisLoansComp from "../Loan/DisbursedLoans"
import useAdminData from "hooks/useAdminData"
import TabHeader from "../TabHeader"
import ApprovedLoans from "../Loan/ApprovedLoans"
import MemoLoans from "../Loan/RefferedLoans/MemoLoans"
// import ApprovedLoans from "components/Dashboard/Loan/ApprovedLoans/index"


const tabs = [
	{label:"Reffered Loans",index:1},
	{label:"Memo Loans",index:2},
	{label:"Approved Loans",index:3},
	{label:"Disbursed Loans",index:4},
	{label:"Rejected Loans",index:5},
]
export default function LPHTabs() {
    const {userProfile,adminRole} = useAdminData()
	const [toggleState, setToggleState] = useState(1)

	return (
		<div clas>
			<TabHeader tabs={tabs} currentTab={toggleState} selectTab={setToggleState}/>
			<div className="">
				{toggleState === 1 && (<RefferedLoans admin_role={adminRole} user={userProfile} />)}
				{toggleState === 2 && (<MemoLoans admin_role={adminRole} user={userProfile} />)}
				{toggleState === 3 && (<ApprovedLoans />)}
				{toggleState === 4 && (<DisLoansComp />)}
				{toggleState === 5 && (<RejLoansComp />)}
			</div>
		</div>
	)
}

