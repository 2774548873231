import { DataField } from "./DataField";

export function InstitutionTab({ loan }) {
    return (
        <div className="grid grid-cols-2 gap-4">
            <DataField
                label="Name Institution"
                value={loan?.data[0]?.institution?.name
                    ? loan?.data[0]?.institution?.name
                    : 'Not Provided'}
            />
            <DataField
                label="Faculty"
                value={loan?.data[0]?.institution?.faculty
                    ? loan?.data[0]?.institution?.faculty
                    : 'Not Provided'}
            />
            <DataField
                label="Year of Study"
                value={loan?.data[0]?.institution?.current_study_year
                    ? loan?.data[0]?.institution?.current_study_year
                    : 'Not Provided'}
            />
            <DataField
                label="Certificate in View"
                value={loan?.data[0]?.certificate
                    ? loan?.data[0]?.certificate
                    : 'Not Provided'}
            />
            <DataField
                label="Course of Study"
                value={loan?.data[0]?.institution?.course
                    ? loan?.data[0]?.institution?.course
                    : 'Not Provided'}
            />
            <DataField
                label="Matric No"
                value={loan?.data[0]?.institution?.matric_number
                    ? loan?.data[0]?.institution?.matric_number
                    : 'Not Provided'}
            />
            <DataField
                label="Year Of Graduation"
                value={loan?.data[0]?.institution?.graduation_year
                    ? loan?.data[0]?.institution?.graduation_year
                    : 'Not Provided'}
            />
            <DataField
                label="Loan Category"
                value={loan?.data[0]?.loan_type
                    ? loan?.data[0]?.loan_type
                    : 'Not Provided'}
            />

        </div>
    )
}