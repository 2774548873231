
import {
  CardFlex,
  DocHeader,
  LoanDocDiv,
} from './style'
import FileCard from './FileCard'
import { wordIcon } from 'utils/assets'

export default function DocumentsTab({loan}){
    return (
        <div>
                      <LoanDocDiv>
                        <DocHeader>Loan Requirement Documents Page</DocHeader>
                        <CardFlex>
                          <FileCard
                            fileTxt="Admission Letter"
                            imgUrl={
                              loan?.data[0]?.admission_letter
                                ? loan?.data[0]?.admission_letter
                                : `${wordIcon}`
                            }
                            imgDesc="document"
                          />
                          <FileCard
                            fileTxt="Signed Letter from School"
                            imgUrl={
                              loan?.data[0]?.signed_letter_id
                                ? loan?.data[0]?.signed_letter_id
                                : `${wordIcon}`
                            }
                            imgDesc="document"
                          />
        
                          {loan?.data[0]?.institution.school_identity_card && (
                            <FileCard
                              fileTxt="School Identity Card"
                              imgUrl={
                                loan?.data[0]?.institution.school_identity_card
                                  ? loan?.data[0]?.institution.school_identity_card
                                  : `${wordIcon}`
                              }
                              imgDesc="document"
                            />
                          )}
        
                          {loan?.data[0]?.school_fee_receipt && (
                            <FileCard
                              fileTxt="Proof of Previous Fee"
                              imgUrl={
                                loan?.data[0]?.school_fee_receipt
                                  ? loan?.data[0]?.school_fee_receipt
                                  : 'Do not apply'
                              }
                              imgDesc="document"
                            />
                          )}
        
                          {loan?.data[0]?.previous_certificate && (
                            <FileCard
                              fileTxt="Previous Certificate"
                              imgUrl={
                                loan?.data[0]?.previous_certificate
                                  ? loan?.data[0]?.previous_certificate
                                  : `${wordIcon}`
                              }
                              imgDesc="document"
                            />
                          )}
        
                        </CardFlex>
        
                        <DocHeader>Personal Documents</DocHeader>
                        <CardFlex>
                          <FileCard
                            fileTxt="Means of Identification"
                            imgUrl={
                              loan?.data[0]?.applicant?.identification
                                ? loan?.data[0]?.applicant?.identification
                                : `${wordIcon}`
                            }
                            imgDesc="document"
                          />
                          <FileCard
                            fileTxt="LGA Certificate"
                            imgUrl={
                              loan?.data[0]?.applicant?.lga_certificate
                                ? loan?.data[0]?.applicant?.lga_certificate
                                : `${wordIcon}`
                            }
                            imgDesc="document"
                          />
                          <FileCard
                            fileTxt="Birth Certificate"
                            imgUrl={
                              loan?.data[0]?.applicant?.birth_certificate
                                ? loan?.data[0]?.applicant?.birth_certificate
                                : `${wordIcon}`
                            }
                            imgDesc="document"
                          />
                        </CardFlex>
                      </LoanDocDiv>
                    </div>
    )
}