import { useState } from "react";
import { downloadLoan } from "services/AdminUserService";
import { useSearchParams } from "react-router-dom";
import { DateTime } from "luxon";
import { convertArrayToCSV } from "convert-array-to-csv";
import dayjs from "dayjs";
import { message } from "antd";

function getUpperCase(value) {
    if (value) return value.toUpperCase();
    return "";
}

export function getCSVData(loanData,loanType) {

    let transformedLoan = []
    const duplicates = []

    for(let index = 0; index < loanData.length;index++){
        const loan = loanData[index];
        const ageDuration = DateTime.now().diff(DateTime.fromJSDate(new Date(loan.applicant.dob)))
        const age = parseInt((ageDuration.milliseconds/1000)/(60 * 60 * 24 * 365))

        const monthly_repayment_starts = DateTime.fromJSDate(new Date(loan?.disburse_date)).plus({years:3})

        const disbursedFields = {
            "DATE DISBURSED": loan?.disburse_date ? `${dayjs(loan?.disburse_date).format("MMM D, YYYY")}` : `${dayjs(loan?.updated_at).format("MMM D, YYYY")}`,
            "MONTHLY REPAYMENT AMOUNT": `${(loan?.monthly_repayment_amount).toLocaleString()}`,
            "MONTHLY REPAYMENT STARTS": `${monthly_repayment_starts.toFormat("dd LLL yyyy")}`
        }

        const statusFields = {
            "STATUS":loan.status.toUpperCase(),
        }
        console.log(loan.applicant)

        const fields =  {
            "S/N": index + 1,
            "NAME": `${loan?.bank_account?.account_name.toUpperCase()}`,
            "PHONE": `+234 ${loan?.applicant?.phone.toString().slice(1)}`,
            "AGE": age,
            "GENDER": loan?.applicant?.gender,
            "LGA": loan?.applicant?.lga,
            "INSTITUTION": loan?.institution?.name ? loan?.institution?.name?.toUpperCase() : "",
            "LEVEL": loan?.institution?.current_study_year ? loan?.institution?.current_study_year?.toUpperCase() : "",
            "COURSE": loan?.institution?.course ? loan?.institution?.course?.toUpperCase() : "",
            "AMOUNT": loan?.amount ? loan?.amount.toLocaleString() : "0.00",
            "INSURANCE": `${(0.01 * loan?.amount).toLocaleString()}.00`,
            "NET AMOUNT": (loan?.amount - (0.01 * loan?.amount)).toLocaleString(),
            "BANK": loan?.bank_account?.bank_name ? loan?.bank_account?.bank_name?.toUpperCase() : "",
            "BANK_CODE": loan?.bank_account?.bank_code ? loan?.bank_account?.bank_code.toString() : "",
            "ACCOUNT NUMBER": `'${loan?.bank_account?.account_number}`,
            "FIRST GUARANTOR": `${loan?.guarantor_one?.surname?.toUpperCase()} ${loan?.guarantor_one?.first_name?.toUpperCase()} ${getUpperCase(loan?.guarantor_one?.middle_name)}`,
            "PHONE (FIRST GUARANTOR)": `'${loan?.guarantor_one?.phone} `,
            "WORK PLACE (FIRST_GUARANTOR)": `${loan?.guarantor_one?.work_place}`,
            "SECOND GUARANTOR": `${loan?.guarantor_two?.surname?.toUpperCase()} ${loan?.guarantor_two?.first_name?.toUpperCase()} ${getUpperCase(loan?.guarantor_two?.middle_name)}`,
            "PHONE (SECOND GUARANTOR)": `'${loan?.guarantor_two?.phone} `,
            "WORK PLACE (SECOND GUARANTOR)": `${loan?.guarantor_two?.work_place}`,
        }


        if(loanType === "disbursed"){
            transformedLoan.push({
                ...fields,
                ...disbursedFields,
            })
            duplicates.push(loan.applicant._id)
            continue

        }
        duplicates.push(loan.applicant._id)
        transformedLoan.push({
            ...fields,
            ...statusFields,
        })


    }
    return transformedLoan;
}

const getFormattedDate = (date) => {
    if (date instanceof Date) {
        const addZeroDigit = (value)=>value > 10 ? `${value}` : `0${value}`
        return {
            date: addZeroDigit(date.getDate()),
            day: addZeroDigit(date.getDay()),
            year: date.getFullYear(),
            month: addZeroDigit(date.getMonth() + 1),
        }
    }
}

export const downloadCSV = async (loanData, loanType) => {
    const csvData = getCSVData(loanData, loanType)
    const csv = convertArrayToCSV(csvData);

    const blob = new Blob([csv], { type: "text/plain" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");

    link.download = `${loanType}_loans.csv`;
    link.href = url;
    link.click();

    URL.revokeObjectURL(url)
}

export function useDownloadForm() {

    const [dateRanges, setDateRanges] = useState(() => {
        const startDate = DateTime.now().minus({ years: 20 }).toJSDate();
        const curentDate = DateTime.now().toJSDate();

        return [startDate, curentDate];
    })

    const start_date =  getFormattedDate(dateRanges[0])
    const end_date = getFormattedDate(dateRanges[1])

    const startDate = `${start_date.year}-${start_date.month}-${start_date.date}`
    const endDate = `${end_date.year}-${end_date.month}-${end_date.date}`

    const handleSelectDate = (value,index=0) => {
        setDateRanges(prev=>{
            const range = [...prev]
            range[index] = new Date(value)
            return range
        })
    }

    const [params] = useSearchParams()

    const loan_type = params.get("loans")

    const [loading, setLoading] = useState(false)

    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            setLoading(true)
            const response = await downloadLoan({
                loan_type,
                from_date: dateRanges[0].toISOString(),
                to_date: dateRanges[1].toISOString(),
            })
            if (!response.data) throw new Error("Sorry could not get loans please try again later")
            if (!response.data.loans) throw new Error("Sorry could not get loans please try again later")
            if (response.data.loans.length === 0) throw new Error("No loans available for download")

            console.log(response.data.loans)
            downloadCSV(response.data.loans, loan_type)


        } catch (error) {
            message.error(error.message)
        }
        finally {
            setLoading(false)
        }
    }
    const handleDownloadAll = async (e) => {
        e.preventDefault()
        try {
            setLoading(true)
            const response = await downloadLoan({
                loan_type,
                from_date: DateTime.now().minus({ years: 20 }).toJSDate().toISOString(),
                to_date:DateTime.now().toJSDate().toISOString(),
            })
            if (!response.data) throw new Error("Sorry could not get loans please try again later")
            if (!response.data.loans) throw new Error("Sorry could not get loans please try again later")
            if (response.data.loans.length === 0) throw new Error("No loans available for download")

            downloadCSV(response.data.loans, loan_type)


        } catch (error) {
            message.error(error.message)
        }
        finally {
            setLoading(false)
        }
    }

    return { loading, handleSubmit, handleDownloadAll,startDate, endDate,handleSelectDate }
}