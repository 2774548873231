export default function CloseIcon() {
    return (
        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="mask0_115_8968" style={{ "maskType": "alpha" }} maskUnits="userSpaceOnUse" x="0" y="0" width="25" height="24">
                <rect x="0.867676" width="24" height="24" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_115_8968)">
                <path d="M9.26768 17L12.8677 13.4L16.4677 17L17.8677 15.6L14.2677 12L17.8677 8.4L16.4677 7L12.8677 10.6L9.26768 7L7.86768 8.4L11.4677 12L7.86768 15.6L9.26768 17ZM12.8677 22C11.4843 22 10.1843 21.7375 8.96768 21.2125C7.75101 20.6875 6.69268 19.975 5.79268 19.075C4.89268 18.175 4.18018 17.1167 3.65518 15.9C3.13018 14.6833 2.86768 13.3833 2.86768 12C2.86768 10.6167 3.13018 9.31667 3.65518 8.1C4.18018 6.88333 4.89268 5.825 5.79268 4.925C6.69268 4.025 7.75101 3.3125 8.96768 2.7875C10.1843 2.2625 11.4843 2 12.8677 2C14.251 2 15.551 2.2625 16.7677 2.7875C17.9843 3.3125 19.0427 4.025 19.9427 4.925C20.8427 5.825 21.5552 6.88333 22.0802 8.1C22.6052 9.31667 22.8677 10.6167 22.8677 12C22.8677 13.3833 22.6052 14.6833 22.0802 15.9C21.5552 17.1167 20.8427 18.175 19.9427 19.075C19.0427 19.975 17.9843 20.6875 16.7677 21.2125C15.551 21.7375 14.251 22 12.8677 22Z" fill="#727272" />
            </g>
        </svg>
    )
}