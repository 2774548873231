import { useState } from "react";
import Collapsible from "react-collapsible";
import ForwardMemo from "./ForwardMemo";
import ForwardApprovedMemo from "./ForwardApprovedMemo";
export function getAdminRole(role){
    if(role === "lo") return "Loan Officer";
    if(role === "lph") return "Loan Processing Head"
    if(role === "es") return "Executive Secretary"
    if(role === "la") return "Auditor"
    if(role === "fdo") return "Front Desk Officer"
    if(role === "blog") return "Blogger"
    if(role === "acc") return "Accountant"
    return "Admin"
}

function getDate(dateString){
    const date = new Date(dateString)
    const day = date.getDate() >= 10 ? date.getDate() : `0${date.getDate()}`
    const month = date.getMonth() + 1 >= 10 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`

    return `${day}-${month} -${date.getFullYear()}`
}

export default function MemoHeader({hasThreads,memo,adminRole,selectedLoans,refetch}){
    const Title = "MEMO RECOMMENDED FOR APPROVAL"
    const AdminRole = getAdminRole(adminRole)
    const [open,setOpen] = useState (false)

    // console.log({memo})

    function toggleCollapse(){
        setOpen(!open)
    }
    return (
        <div className="bg-white p-4 rounded-md mt-12 top-0 z-10">
           <div className="mt-2">
                <p>{AdminRole} - Auditor</p>
                <div className="flex gap-1 text-lg items-center border-b border-gray-300 pb-2 mb-2">
                    <h4 className="font-bold text-lg text-primary">{Title}</h4>
                    <p className="text-gray-400 text-sm">({getDate(memo.created_at)})</p>
                   <div className="ml-auto flex items-center gap-4">
                        <button className="text-base font-medium px-4 py-2 text-white bg-primary rounded-md" onClick={toggleCollapse}>{open?"Hide":"Show"} Note</button>
                   </div>
                </div>
                <Collapsible open={open}>
                    <div className="p-3 my-2 rounded-md">
                        <p>{memo.note}</p>
                    </div>
                   {!hasThreads &&  <ForwardMemo selectedLoans={selectedLoans} memo_id={memo.memo_id} sender={memo.admin._id} receiver={memo.to_admin._id}/>}
                </Collapsible>
           </div>
          <ForwardApprovedMemo selectedLoans={selectedLoans} memo={memo} refetch={refetch}  receiver={memo.to_admin._id}/>
        </div>
    )
}