import AllMesComp from './MemoTable'

import {
  MemoContent,
  MemoTabDiv,
} from './style'

const MemoTab = () => {

  return (
    <MemoTabDiv>
      <MemoContent>
         <AllMesComp />
      </MemoContent>
    </MemoTabDiv>
  )
}

export default MemoTab
