import { DataField } from './DataField'
export default function BvnTab({loan}){
    return (
         <div>
            <DataField
                label={"Bank Verification Number (BVN)"}
                value= {loan?.data[0]?.applicant?.bvn
                    ? loan?.data[0]?.applicant?.bvn
                    : 'Not Provided'}
            />
        </div>
    )
}