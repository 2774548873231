import TextInput from "components/TextInput"
import { useFormik } from "formik"
import { Div } from "globalStyles"
import authStore from "mobx/AuthStore"
import React, { useState } from "react"
import { CtaMemo, CtaMemoCon, ModalDiv, ProMemoBtn, } from "./style"

export default function LoanActionButton ({
	isActive,
	amount,
	btnText,
	bg,
	handleSubmit,
	validationSchema,
	modalTitle,
	disabled=false
	}){
	const [open,setOpen] = useState(false)
	const toggleModal = ()=>{
		setOpen(!open)
	}
	const closeModal = ()=>setOpen(false)
	const formik = useFormik({
		enableReinitialize: true,
		initialValues: {
			comment: "",
			amount,
		},
		onSubmit:(data,helpers)=>handleSubmit(data,helpers,closeModal),
		validationSchema: validationSchema
	})

	if(isActive){
		return(
			<>
				<button style={{background:(bg || "#66BB6A")}} onClick={toggleModal} className="btn-modal p-4 py-2 rounded-md text-white font-poppins">{btnText}</button>
				{open && (
					<ModalDiv>
					<div className="modal">
						{/* <div className="overlay"></div> */}

						<form className="modal-content" onSubmit={formik.handleSubmit}>
							<CtaMemoCon>
								<Div width="100%" display="flex" justify="flex-end">
									<button type="button" className="close-modal" onClick={toggleModal}>
										CLOSE
									</button>
								</Div>
								<h4>{modalTitle}</h4>
								{/* <p>{action === "approved" ? "If yes, leave a comment below and proceed" : ""}</p> */}
								<TextInput
									label="Amount"
									name="amount"
									id="amount"
									disabled={disabled}
									style={{marginBottom:"16px",opacity:disabled?"0.5":null}}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									value={formik.values.amount}
									error={formik.errors.amount}
									visible={formik.touched.amount}
									placeholder="Amount"
								/>
								<CtaMemo name="comment" id="comment" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.comment}>
									Write a memo here...
								</CtaMemo>{" "}
								<ProMemoBtn type="submit">{authStore?.isLoading ? "Loading..." : "Procced"}</ProMemoBtn>
							</CtaMemoCon>
						</form>
					</div>
				</ModalDiv>
				)}
			</>
		)
	}
	return null
}