import { MemoContent} from './style'
import LPHMemoTable from './MemoTable/LPHMemoTable'

const LPHMemoTab = () => {
    return (
        <div className="mt-8">
            <MemoContent>
                <LPHMemoTable/>
            </MemoContent>
        </div>
    )
}

export default LPHMemoTab
