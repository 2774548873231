import { message, Modal, Spin } from "antd";
import { useState } from "react";
import { deleteMultipleDrafts } from "services/LoanService";

export default function DeleteAllDraftsButton({ disabled, selectedLoanDrafts, refetch }) {

    const [loading, setLoading] = useState(false)
    const [opened, setOpened] = useState(false)
    const openModal = () => setOpened(true)
    const closeModal = () => setOpened(false)

    const deleteLoanDrafts = async () => {
        try {
            setLoading(true)
            const res = await deleteMultipleDrafts(selectedLoanDrafts)
            if (res) {
                closeModal()
                message.success("Loans deleted successfully")
                await refetch()
            }
        } finally {
            setLoading(false)
        }

    }

    return (
        <>
            <button
                onClick={openModal}
                disabled={disabled}
                className="bg-red-400 ml-auto disabled:opacity-40 disabled:cursor-not-allowed rounded-lg text text-white px-4 py-2">
                Delete Selected
            </button>
            <Modal open={opened} closable={false} maskClosable={false} closeIcon={false} centered footer={null}>
                <p className="text-3xl text-center font-semibold">Confirm Delete</p>
                <p className="my-4 text-center">Are you sure you wish to delete all selected loan draft, this action can not be reversed</p>
                <div className="flex gap-4 justify-center items-center">
                    <button onClick={closeModal} className="bg-red-100 text-red-600 border-red-400 rounded-full px-6 py-2">Cancel</button>
                    <button disabled={loading} onClick={deleteLoanDrafts} className="bg-primary-100 disabled:opacity-60 disabled:cursor-not-allowed text-primary-600 border-primary-400 rounded-full px-6 py-2">
                        {
                            loading
                                ? <Spin />
                                : <span>Continue</span>
                        }
                    </button>
                </div>
            </Modal>
        </>
    )
}