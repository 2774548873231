import { message, Modal, Spin } from "antd"
import { useState } from "react"
import { sendMultipleDraftEmailReminder } from "services/LoanService"

export default function SendRemindersButton({ disabled, selectedLoanDrafts }) {

    const [loading, setLoading] = useState(false)
    const [opened, setOpened] = useState(false)
    const openModal = () => setOpened(true)
    const [comment, setComment] = useState("")
    const closeModal = () => setOpened(false)

    const sendReminders = async () => {
        try {
            setLoading(true)
            const res = await sendMultipleDraftEmailReminder({
                ids: selectedLoanDrafts,
                comment,
            })
            if (res) {
                closeModal()
                message.success("Reminders sent successfully")
            }
        } finally {
            setLoading(false)
        }

    }


    return (
        <>
            <button onClick={openModal} disabled={disabled} className="bg-primary rounded-lg disabled:opacity-40 disabled:cursor-not-allowed text-white px-4 py-2">Send Reminders</button>
            <Modal open={opened} closable={false} maskClosable={false} closeIcon={false} centered footer={null}>
                <p className="text-3xl text-center font-semibold mb-4 text-secondary">Email Reminder</p>
                <p className="mt-8 text-lg font-medium">Comment</p>
                <textarea type="text"
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    style={{ outline: "none" }}
                    className="border w-full mb-6 p-2 rounded-md resize-none"
                    cols={4}
                    placeholder="Add Reminder Comment"
                />
                <div className="flex gap-4 justify-center items-center">
                    <button onClick={closeModal} className="bg-red-100 text-red-600 border-red-400 rounded-full px-6 py-2">Cancel</button>
                    <button disabled={loading} onClick={sendReminders} className="bg-primary-100 disabled:opacity-60 disabled:cursor-not-allowed text-primary-600 border-primary-400 rounded-full px-6 py-2">
                        {
                            loading
                                ? <Spin />
                                : <span>Continue</span>
                        }
                    </button>
                </div>
            </Modal>
        </>
    )
}