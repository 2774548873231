import LoanComp from 'components/Dashboard/Loan'
import DashboardHeader from 'components/Dashboard/shared/DashboardHeader'
import MainLayout from 'layout/MainLayout'
import React from 'react'

const LoanPage = () => {
  return (
    <MainLayout>
      <DashboardHeader title="Loans" back/>
      <div className="mt-16">
        <LoanComp />
      </div>
    </MainLayout>
  )
}

export default LoanPage