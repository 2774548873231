export default function GuarantorTab({loan}){
    const {guarantor_one,guarantor_two} = loan
    return (
      <div className="bg-white rounded-md p-4">
        <section className="my-4 mb-8">
          <h3 className="text-xl font-bold mb-2 text-primary">First Guarantor's Details</h3>
          <div className="grid grid-cols-2 gap-2">
            <div>
              <div className="mb-4">
                <p className="text-sm">Full Name</p>
                <p className="text-base font-medium">{guarantor_one.first_name + " " + guarantor_one.surname}</p>
              </div>
              <div className="mb-4">
                <p className="text-sm">Work Place</p>
                <p className="text-base font-medium">{guarantor_one.work_place}</p>
              </div>
              <div className="">
                <p className="text-sm">NIN</p>
                <p className="text-base font-medium">{guarantor_one.nin}</p>
              </div>
            </div>

            <div>
              <div className="mb-4">
                <p className="text-sm">Email</p>
                <p className="text-base font-medium">{guarantor_one.email}</p>
              </div>
              <div className="my-1">
                <p className="text-sm">Phone No</p>
                <p className="text-base font-medium">{guarantor_one.phone}</p>
              </div>
            </div>
          </div>
        </section>
        <section className="my-4">
          <h3 className="text-xl font-bold mb-2 text-primary">Second Guarantor's Details</h3>
          <div className="grid grid-cols-2 gap-2">
            <div>
              <div className="mb-4">
                <p className="text-sm">Full Name</p>
                <p className="text-base font-medium">{guarantor_two.first_name + " " + guarantor_two.surname}</p>
              </div>
              <div className="mb-4">
                <p className="text-sm">Work Place</p>
                <p className="text-base font-medium">{guarantor_two.work_place}</p>
              </div> 
              <div className="">
                <p className="text-sm">NIN</p>
                <p className="text-base font-medium">{guarantor_two.nin}</p>
              </div>
            </div>
            <div>
              <div className="mb-4">
                <p className="text-sm">Email</p>
                <p className="text-base font-medium">{guarantor_two.email}</p>
              </div>
              <div className="my-1">
                <p className="text-sm">Phone No</p>
                <p className="text-base font-medium">{guarantor_two.phone}</p>
              </div>
            </div>
          </div>
        </section>
        {/* <section className="my-4">
          <h3 className="text-xl font-bold mb-4">Second Guarantor's Details</h3>
        </section> */}
      </div>
    )
  }