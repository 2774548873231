import dayjs from "dayjs";
import { Link } from "react-router-dom";
import { DASHBOARD_RE_APPLICANT_ROUTE } from "routes";

export const COLUMNS = [
  {
    Header: "Applicant",
    accessor: row => `${row.surname} ${row.first_name}`,
  },
  {
	Header: "BVN",
	accessor: "bvn",
  },
  {
	Header: "Date of Birth",
	accessor: "dob",
	Cell: ({ value }) => {
		return dayjs(value).format('DD/MM/YYYY');
	  },
  },
  {
    Header: "Phone No",
    accessor: "phone",
  },
  {
    Header: "Email",
    accessor: "email",
  }
];


export const REAPPLICANT_COLUMN = [
  {
    title: "S/N",
    key:"s/n",
    width:250,
    render: (_,originalRow,index) => <p>{index + 1}</p>
  },
  {
    title: "Applicant",
    key:"applicant",
    width:250,
    render: (_,originalRow,) => <div style={{ maxWidth: "250px" }}>{originalRow?.first_name} {originalRow?.surname}</div>
  },
  {
    title: "BVN",
    key:"bvn",
    width:250,
    render: (_,originalRow,) => <div style={{ maxWidth: "250px" }}>{originalRow?.bvn}</div>
  },
  {
    title: "Date of Birth",
    key:"dob",
    width:250,
    render: (_,originalRow,) => <div style={{ maxWidth: "250px" }}>{dayjs(originalRow.dob).format('DD/MM/YYYY')}</div>
  },
  {
    title: "Phone Number",
    key:"Phone number",
    width:200,
    render: (_,originalRow,) => <div style={{ maxWidth: "250px" }}>{originalRow.phone}</div>

  },
  {
    title: "Email",
    key:"email",
    width:250,
    render: (_,originalRow,) => <div style={{ maxWidth: "250px" }}>{originalRow?.email}</div>
  },
  {
    title: "More",
    key:"more",
    fixed:"right",
    width:150,
    render: (_,originalRow,) => <Link to={`${DASHBOARD_RE_APPLICANT_ROUTE}/${originalRow.id}`}><button className="px-6 py-1 justify-center font-semibold rounded-full border border-primary-300 bg-secondary-50 text-primary-700">View</button></Link>
  },
]
