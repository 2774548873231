import { DataField } from "./DataField";

export default function AccountDetailsTab({ loan }) {
    return (
        <div className="grid grid-cols-2 gap-4" >
            <DataField
                label={"Account Name"}
                value={
                    loan?.data[0]?.applicant?.bank_account?.account_name
                        ? loan?.data[0]?.applicant?.bank_account?.account_name
                        : 'Not Provided'
                }
            />
            <DataField
                label={"Account Number"}
                value={
                    loan?.data[0]?.applicant?.bank_account?.account_number
                        ? loan?.data[0]?.applicant?.bank_account?.account_number
                        : 'Not Provided'
                }
            />
            <DataField
                label={"Bank Name"}
                value={loan?.data[0]?.applicant?.bank_account?.bank_name
                    ? loan?.data[0]?.applicant?.bank_account?.bank_name
                    : 'Not Provided'}
            />
            <DataField
                label={"Bank Code"}
                value={loan?.data[0]?.applicant?.bank_account?.bank_code
                    ? loan?.data[0]?.applicant?.bank_account?.bank_code
                    : 'Not Provided'}
            />
        </div>
    )
}