import React, { useState } from "react"

const FilterComp = ({ filter, setFilter }) => {
	const [value, setValue] = useState(filter)

	const handleChange = (e) => {
		setValue(e.target.value)
		setFilter(value)
	}

	return (
		<div className="flex-1 bg-[#F9F9F9] border border-[#959595] max-w-[400px] my-2  border-solid rounded-md w-full p-2">
			<input
			    className="bg-transparent w-full"
				value={value || ""}
				style={{outline: "none"}}
				onChange={handleChange}
				placeholder="Search Table"
			/>
		</div>
	)
}

export default FilterComp
