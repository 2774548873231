import React, { useState } from "react"
import PendLoansComp from "../Loan/PendingLoans"
import RejLoansComp from "../Loan/RejectedLoans"
import RefferedLoans from "../Loan/RefferedLoans"
import DisLoansComp from "../Loan/DisbursedLoans"
import useAdminData from "hooks/useAdminData"
import TabHeader from "../TabHeader"
import ApprovedLoanTable from "../Loan/ApprovedLoans/ApprovedLoansTable"

const tabs = [
	{label:"Pending",index:1},
	{label:"Reffered",index:2},
	{label:"Disbursed",index:3},
	{label:"Approved",index:4},
	{label:"Rejected",index:5},
]

export function TabContent({currentTab}){
	const {userProfile,adminRole} = useAdminData()
	if(currentTab === 1) return <PendLoansComp />;
	if(currentTab === 2){
		return <RefferedLoans admin_role={adminRole} user={userProfile}/>
    }
	if(currentTab === 3) return <DisLoansComp/>;
	if(currentTab === 4) return <ApprovedLoanTable/>;
	return <RejLoansComp />

}

export default function LoanOfficerTabs() {
	const [toggleState, setToggleState] = useState(1)

	return (
		<section>
			<TabHeader tabs={tabs} selectTab={setToggleState} currentTab={toggleState}/>
			<div className="mt-4">
				<TabContent currentTab={toggleState}/>
			</div>
		</section>
	)
}

