export default function BellIcon({width="18", height="18"}) {
    return (
        <svg width={width} height={height} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="mask0_134_9059" style={{ "maskType": "alpha" }} maskUnits="userSpaceOnUse" x="0" y="0" width="18" height="18">
                <rect width="18" height="18" fill="#1C1B1F" />
            </mask>
            <g mask="url(#mask0_134_9059)">
                <path d="M3 14.25V12.75H4.5V7.5C4.5 6.4625 4.8125 5.54063 5.4375 4.73438C6.0625 3.92812 6.875 3.4 7.875 3.15V2.625C7.875 2.3125 7.98437 2.04688 8.20312 1.82812C8.42188 1.60937 8.6875 1.5 9 1.5C9.3125 1.5 9.57812 1.60937 9.79688 1.82812C10.0156 2.04688 10.125 2.3125 10.125 2.625V3.15C11.125 3.4 11.9375 3.92812 12.5625 4.73438C13.1875 5.54063 13.5 6.4625 13.5 7.5V12.75H15V14.25H3ZM9 16.5C8.5875 16.5 8.23438 16.3531 7.94063 16.0594C7.64688 15.7656 7.5 15.4125 7.5 15H10.5C10.5 15.4125 10.3531 15.7656 10.0594 16.0594C9.76563 16.3531 9.4125 16.5 9 16.5Z" fill="#575757" />
            </g>
        </svg>

    )
}