import React, { useState } from "react"
import { useQuery } from "react-query"
import FullScreenLoader from "../FullScreenLoader"
import { BASE_URL } from "services"
import http from "services/httpService"
import MemoHeader from "./MemoHeader"
import { useMemoData } from "hooks/useMemoData"
import MemoBody from "./MemoBody"

function parseJSON(jsonData,defaultValue){
	try {
		return JSON.parse(jsonData)
	} catch (error) {
		return defaultValue
	}
}

const MemoDetails = () => {
	const {loading,memo,selectLoan,selectedLoans,refetch} = useMemoData()
	const [toggleMsg, setToggleMsg] = useState(false)

	const toggleHandler = () => {
		setToggleMsg(!toggleMsg)
	}
	const url = `${BASE_URL}/admin/who_i_am`
	const fetcher = async () => {
		const res = await http.get(url)
		return res?.data?.data
	}

	const { data: userProfile } = useQuery("profile", fetcher)
	if(loading){
		return <FullScreenLoader show={true} />
	}
	if(!memo) return null;

	const loans =  !loading && parseJSON(memo.applications,[])
	return (
		<section className="">
			<MemoHeader
				memo={memo}
				refetch={refetch}
				hasThreads={memo.threads.length > 0 }
				adminRole={memo.admin.admin_role}
				toggleMemo={toggleHandler}
				selectedLoans={selectedLoans}
			/>
			<div className="my-7">
				<p className="text-center mt-4 my-8 text-xl">MEMO RESPONSE THREAD</p>
				<MemoBody
					selectLoan={selectLoan}
					refetch={refetch}
					selectedLoans={selectedLoans}
					hasThreads={memo.threads.length > 0}
					singleMemo={memo} loans={loans}
					adminRole={userProfile.admin_role}/>
			</div>
		</section>
	)
}

export default MemoDetails
