import { Img } from "globalStyles"
import React, { useEffect } from "react"
import { AuthNavUser } from "utils/assets"
import MenuDas from "../Menu"
import BigMenu from "../Menu/BigMenu"
import { BigMenuDiv, LogoutDiv, SideImageDiv, UserDpName } from "./style"
import logoutActive from "assets/icons/logoutActive.svg"
import { DASHBOARD_LOGOUT_ROUTE, LOGIN_ROUTE } from "routes"
import authStore from "mobx/AuthStore"
import { useNavigate } from "react-router-dom"

const AuthSideNav = ({ isOpen, onClick, user }) => {
	const navigate = useNavigate()
	useEffect(() => {
		const status = authStore.loggedInStatus()

		if (!status) {
			navigate(`${LOGIN_ROUTE}`)
		}
	}, [navigate])

	return (
		<BigMenuDiv>
			<SideImageDiv>
				<Img src={user?.avatar?.url ? user?.avatar?.url : AuthNavUser} alt="byshelb user" />
			</SideImageDiv>
			<UserDpName>
				{user?.surname} {user?.first_name}
			</UserDpName>
			<MenuDas user={user} />
		</BigMenuDiv>
	)
}

export default AuthSideNav
