import FullScreenLoader from "components/Dashboard/FullScreenLoader"
import React, { useEffect, useMemo, useState } from "react"
import { useQuery } from "react-query"
import {getRefferedLoans } from "services/LoanService"
import FilterComp from "../FilterComp"
import { getAppColumns } from "./appColumns"
import { observer } from "mobx-react-lite"
import { Table } from "antd"
import { useSearchParams } from "react-router-dom"
import { usePaginate } from "hooks/usePagination"


function FilterHeader({globalFilter,setGlobalFilter}) {
	return (
		<div className="flex items-center justify-between gap-8 my-2">
            <div className="flex-1">
                <FilterComp filter={globalFilter} setFilter={setGlobalFilter} />
            </div>
        </div>
	)
}

const MemoLoans = ({ admin_role }) => {
	const [params,setParams] = useSearchParams()
	const [searchQuery,setSearchQuery] = useState("")
	const [filter, setFilter] = useState("all")
	const [loanFilter, setLoanFilter] = useState("all")
	const { data: referredLoans, isLoading} = useQuery("approved-loans", getRefferedLoans)


	const filterData = (arrData)=>{
        if(!searchQuery) return arrData
		return arrData.filter((loan)=>{
			if(!loan) return true;
			return loan.applicant.first_name.toLowerCase().includes(searchQuery.toLowerCase())
			|| loan.applicant.surname.toLowerCase().includes(searchQuery.toLowerCase())
		})
	}

	const handleSetParams = (_filter,_loanFilter)=>{
		setParams({
			filter:_filter,
			loan_filter:_loanFilter
		})
	}

	const handleFiilter=(_filter)=>{
		handleSetParams(_filter,loanFilter)
	}
	const handleLoanFiilter=(_loanFilter)=>{
		handleSetParams(filter,_loanFilter)
	}

	useEffect(()=>{
		if(params){
			const _filter = params.get("filter")
			const _loanFilter = params.get("loan_filter")
			setFilter(_filter || filter)
			setLoanFilter(_loanFilter || loanFilter)
		}
	},[params,loanFilter,filter])


	const columns = useMemo(() => {
		return [
			...getAppColumns(admin_role,loanFilter === "edit",true),
		]
	},[admin_role,loanFilter])


	const data = useMemo(() => {
		if(!referredLoans) return [];
		if(!referredLoans.data) return [];
		return referredLoans?.data.filter(loan=>loan.in_memo)
	}, [referredLoans])

	const getLoanStats = ()=>{
		const loans = data ? data.map(loan=>loan.amount) : []
		return {
			total:loans.length,
			totalAmount:loans.reduce((a,b)=>a+b,0),
		}
	}

	const {pageData,currentPage,setPage,next,prev} = usePaginate(data)

	if (isLoading) return <FullScreenLoader show={true} />

	return (
		<>
			<div className="mt-3">
				<FilterHeader
					loanFilter={loanFilter}
					filter={filter}
					handleFilter={handleFiilter}
					handleLoanFilter={handleLoanFiilter}
					admin_role={admin_role}
					globalFilter={searchQuery}
					setGlobalFilter={setSearchQuery}
				/>
				<div className="max-w-[1024px]">
					<Table
						loading={isLoading}
						columns={columns}
						pagination={{
							current:currentPage,
							onChange:(pageNumber)=>{
								setPage(pageNumber)
							},
							pageSize:10,
							nextIcon:()=>{
								return (
									<button onClick={next}> <i className="bi bi-chevron-right"></i></button>
								)
							},
							prevIcon:()=>{
								return (
									<button onClick={prev}> <i className="bi bi-chevron-left"></i></button>
								)
							}
						}}
						dataSource={filterData(pageData)}
						scroll={{x: 1300}}
						footer={()=>{
							const {totalAmount,total} = getLoanStats()
							return (
								<div className="flex items-center  justify-between">
									<p><span className="font-medium">Total Loan Amount</span> : ₦{totalAmount.toLocaleString()}</p>
									<p><span className="font-medium">Total Loan</span> :{total}</p>
								</div>
							);
						}}
					/>
				</div>
			</div>
		</>
	)
}

export default observer(MemoLoans)
