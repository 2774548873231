import { DataField } from "./DataField";

export default function NextOfKinTab({loan}){
    return (
        <div className="grid grid-cols-2 gap-4">
            <DataField
                label={"Surname"}
                value={
                    loan?.data[0]?.applicant?.next_of_kin?.surname
                  ? loan?.data[0]?.applicant?.next_of_kin?.surname
                  : 'Not Provided'
                }
            /> 
            <DataField
                label={"Middle Name"}
                value={
                    loan?.data[0]?.applicant?.next_of_kin?.surname
                  ? loan?.data[0]?.applicant?.next_of_kin?.surname
                  : 'Not Provided'
                }
            /> 
            <DataField
                label={"Address"}
                value={
                    loan?.data[0]?.applicant?.next_of_kin?.address
                  ? loan?.data[0]?.applicant?.next_of_kin?.address
                  : 'Not Provided'
                }
            /> 
            <DataField
                label={"First Name"}
                value={
                    loan?.data[0]?.applicant?.next_of_kin?.first_name
                  ? loan?.data[0]?.applicant?.next_of_kin?.first_name
                  : 'Not Provided'
                }
            />
            <DataField
                label={"Phone Number"}
                value={
                    loan?.data[0]?.applicant?.next_of_kin?.phone
                  ? loan?.data[0]?.applicant?.next_of_kin?.phone
                  : 'Not Provided'
                }
            />
            <DataField
                label={"Relationship with Applicant"}
                value={
                    loan?.data[0]?.applicant?.next_of_kin?.relationship
                  ? loan?.data[0]?.applicant?.next_of_kin?.relationship
                  : 'Not Provided'
                }
            />
        {/* <NokConDiv>
          <NokLeft>
            <div>
              <label>Surname</label>
              <h4>
                {loan?.data[0]?.applicant?.next_of_kin?.surname
                  ? loan?.data[0]?.applicant?.next_of_kin?.surname
                  : 'Not Provided'}
              </h4>
            </div>
            <div>
              <label>Middle Name</label>
              <h4>
                {loan?.data[0]?.applicant?.next_of_kin?.middle_name
                  ? loan?.data[0]?.applicant?.next_of_kin?.middle_name
                  : 'Not Provided'}
              </h4>
            </div>
            <div>
              <label>Address</label>
              <h4>
                {loan?.data[0]?.applicant?.next_of_kin?.address
                  ? loan?.data[0]?.applicant?.next_of_kin?.address
                  : 'Not Provided'}
              </h4>
            </div>
          </NokLeft>
          <NokRight>
            <div>
              <label>Firstname</label>
              <h4>
                {loan?.data[0]?.applicant?.next_of_kin?.first_name
                  ? loan?.data[0]?.applicant?.next_of_kin?.first_name
                  : 'Not Provided'}
              </h4>
            </div>
            <div>
              <label>Phone</label>
              <h4>
                {loan?.data[0]?.applicant?.next_of_kin?.phone
                  ? loan?.data[0]?.applicant?.next_of_kin?.phone
                  : 'Not Provided'}
              </h4>
            </div>
            <div>
              <label>Relationship with Applicant</label>
              <h4>
                {loan?.data[0]?.applicant?.next_of_kin?.relationship
                  ? loan?.data[0]?.applicant?.next_of_kin?.relationship
                  : 'Not Provided'}
              </h4>
            </div>
          </NokRight>
        </NokConDiv> */}
      </div>
    )
}