import { message, Modal, Skeleton, Spin } from "antd";
import DashboardHeader, { useAdminNotifications } from "components/Dashboard/shared/DashboardHeader";
import MainLayout from "layout/MainLayout";
import { DateTime } from "luxon";
import { useState } from "react";
import { Link } from "react-router-dom";
import { readAllNotification, readNotification, removeAllNotifications, removeNotification } from "services/AdminUserService";


function NotificationLoader(){
    return (
        <ul className="flex flex-col p-4 gap-y-6">
           {new Array(3).fill("").map(()=>(
            <li className="shadow-lg p-4 border rounded-md">
                <div className="mb-1">
                    <Skeleton active paragraph={{rows:1}}/>
                </div>
                <footer className="flex items-center justify-between">
                    <Skeleton.Button active style={{height:"20px"}}/>
                    <Skeleton.Button active style={{height:"20px"}}/>
                </footer>
            </li>
           ))}
        </ul>
    )
}

function NotificationItem({notification,refetch}){
    const [confirmationOpen,setConfirmationOpen] = useState(false)
    const [deleting,setDeleting] = useState(false)

    const openConfirmation = () => setConfirmationOpen(true)
    const closeConfirmation = () => setConfirmationOpen(false)

    const handleDelete = async()=>{
        setDeleting(true)
        const res = await removeNotification(notification._id)
        setDeleting(false)
        if(!res.data){
            message.error("Could remove notification, please check your network and try again")
            return;
        }
        closeConfirmation()
        refetch()
    } 
    const handleReadNotification = async()=>{
        setDeleting(true)
        const res = await readNotification(notification._id)
        setDeleting(false)
        if(!res.data){
            message.error("Could mark notification as read, please check your network and try again")
            return;
        }
        closeConfirmation()
        refetch()
    }
    const date = DateTime.fromJSDate(new Date(notification.createdAt)).toRelative();
    const memoId = notification.payload?.memo_id

    return (
        <>
         <Modal onCancel={closeConfirmation} open={confirmationOpen} footer={null} centered>
                <div className="p-4">
                    <header className="my-2">
                        <p className="text-xl font-bold">Confirm Remove Notification</p>
                        <p>Are you sure you want to  this notification, this cannot be reversed</p>
                    </header>
                    <footer className="flex items-center justify-center mt-5 gap-x-4">
                    <button onClick={closeConfirmation} className="text-xs ml-auto font-bold flex items-center gap-x-2 py-2 px-5 rounded-lg bg-primary text-white">
                        <span>Cancel </span>
                    </button>
                    <button onClick={handleDelete} disabled={deleting} className="text-xs font-bold flex items-center gap-x-2 py-2 px-5 rounded-lg bg-red-400 text-white">
                        {deleting ? (
                            <Spin/>
                        ):(
                            <span>Continue</span>
                        )}
                    </button>
                    </footer>
                </div>
            </Modal>
        <li className="text-[#959595] relative text-base py-2 p-4 border-b items-center gap-x-[7px]">
            <button onClick={openConfirmation} className="text-red-600 absolute top-0 right-4 h-6 w-6 flex items-center justify-center rounded-full">
                <i className="bi bi-trash"></i>
            </button>
            <p className="font-bold text-[#140D47]">{notification.message}</p>
            <p className="text-sm">{date}</p>
            <div className="my-2 flex items-center justify-between gap-x-4">
               {!notification.read && (
                 <button onClick={handleReadNotification} className="text-xs font-bold flex items-center gap-x-2 py-2 px-5 rounded-lg border border-primary-400 text-primary">
                 <span>Mark as Read </span>
                 <span><i className="bi bi-check2-all"></i></span>
             </button>
               )}
                <Link to={`/memo/memo-details/${memoId}`} className="ml-auto inline-block">
                    <button className="text-xs ml-auto font-bold flex items-center gap-x-2 py-2 px-5 rounded-lg bg-primary text-white">
                        <span>View</span>
                        <span><i className="bi bi-arrow-right"></i></span>
                    </button>
                </Link>
            </div>
        </li>
        </>
        )
}

function NotificationList({notifications,loading,refetch}){
    if(loading){
        return (
            <NotificationLoader/>
        )
    }
    return (
        <ul className="my-[18px] flex flex-col gap-y-[23px] mb-[13px]">
        {notifications.map((notification) =>(
            <NotificationItem key={notification._id} refetch={refetch} notification={notification}/>
        ))}
    </ul>
    )
}


export default function NotificationPage(){
    const [confirmationOpen,setConfirmationOpen] = useState(false)
    const [deleting,setDeleting] = useState(false)
    const {notifications,loading,refetch} = useAdminNotifications()

    const openConfirmation = () => setConfirmationOpen(true)
    const closeConfirmation = () => setConfirmationOpen(false)

    const handleDelete = async()=>{
        setDeleting(true)
        const notificationIds = notifications.map((notification) => notification._id)
        const res = await removeAllNotifications(notificationIds)
        setDeleting(false)
        if(!res.data){
            message.error("Could remove all notifications please check your network and try again")
            return;
        }
        closeConfirmation()
        refetch()
    }
    const handleMarkAllAsRead = async()=>{
        const notificationIds = notifications.map((notification) => notification._id)
        const res = await readAllNotification(notificationIds)
        if(!res.data){
            message.error("Could mark all notifications as read, please check your network and try again")
            return;
        }
        closeConfirmation()
        refetch()
    }

    return (
            <>
            <Modal onCancel={closeConfirmation} open={confirmationOpen} footer={null} centered>
                <div className="p-4">
                    <header className="my-2">
                        <p className="text-xl font-bold">Confirm Remove Notifications</p>
                        <p>Are you sure you want to remove all notifications, this cannot be reversed</p>
                    </header>
                    <footer className="flex items-center justify-center mt-5 gap-x-4">
                    <button onClick={closeConfirmation} className="text-xs ml-auto font-bold flex items-center gap-x-2 py-2 px-5 rounded-lg bg-primary text-white">
                        <span>Cancel </span>
                    </button>
                    <button onClick={handleDelete} disabled={deleting} className="text-xs font-bold flex items-center gap-x-2 py-2 px-5 rounded-lg bg-red-400 text-white">
                        {deleting ? (
                            <Spin/>
                        ):(
                            <span>Continue</span>
                        )}
                    </button>
                    </footer>
                </div>
            </Modal>
            <MainLayout>
                <DashboardHeader back title="Notifications"/>
                <section className="mt-10">
                    <div className="bg-white rounded-lg max-w-4xl mx-auto min-h-[20vh] max-h-[80vh] overflow-y-auto">
                        <header className="p-4 z-[10] bg-white sticky top-0 border-b flex items-center gap-x-4">
                            <p className="font-medium text-lg">All Notifications ({notifications.length})</p>
                            <button onClick={handleMarkAllAsRead} className="text-xs ml-auto font-bold flex items-center gap-x-2 py-2 px-5 rounded-lg bg-primary text-white">
                                <span>Mark All as Read </span>
                                <span><i className="bi bi-check2-all"></i></span>
                            </button>
                            <button onClick={openConfirmation} className="text-xs font-bold flex items-center gap-x-2 py-2 px-5 rounded-lg bg-red-400 text-white">
                                <span>Remove All</span>
                                <span><i className="bi bi-trash"></i></span>
                            </button>
                        </header>
                        <NotificationList
                                loading={loading}
                                notifications={notifications}
                                refetch={refetch}
                            />
                    </div>
                </section>
            </MainLayout>
            </>
    )
}