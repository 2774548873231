import DownloadIcon from "components/icons/download";
// import ShareIcon from "components/icons/share";
import ThreeDots from "components/icons/three-dots";
import { useState } from "react";
import { Link } from "react-router-dom";

export default function InfoCard({ icon, title, value, loanType, url }) {
    const [open, setOpen] = useState(false);

    if (url) {
        return (
            <Link to={url}>
                <div className="bg-white min-h-[130px] relative py-3 px-3 rounded-xl">
                    <header className="flex w-[90%] mb-[21px] items-center gap-[7px]">
                        <div className="h-[41px] bg-[#E7E7ED] rounded-full flex items-center justify-center w-[41px]">
                            {icon}
                        </div>
                        <p className="text-sm text-[#575757]">{title}</p>
                    </header>
                    <div className="text-2xl font-bold text-[#3C3C3C]"><p>{value}</p></div>
                </div>
            </Link>
        )
    }

    return (
        <div className="bg-white min-h-[130px] relative py-3 px-3 rounded-xl">
            <header className="flex w-[90%] mb-[21px] items-center gap-[7px]">
                <div className="h-[41px] bg-[#E7E7ED] rounded-full flex items-center justify-center w-[41px]">
                    {icon}
                </div>
                <p className="text-sm text-[#575757]">{title}</p>
            </header>
            {open && (
                <div className="absolute text-[#959595] bg-white z-[10] shadow-lg top-[32px] left-3/4">
                    <Link to={`/download?loans=${loanType}`}>
                        <button className="px-5 flex min-w-[150px] items-center gap-[9px] py-2 border-b border-[#BDBDBD] text-xs font-bold">
                            <DownloadIcon />
                            <span>Download</span>
                        </button>
                    </Link>
                    {/* <Link to={`/share?loans=${loanType}`}>
                  <button className="px-5 flex min-w-[150px] gap-[9px] items-center py-2 text-xs font-bold">
                      <ShareIcon/>
                      <span>Share Data</span>
                  </button>
              </Link> */}
                </div>
            )}
            <button onClick={() => setOpen(prev => !prev)} className="absolute top-2 right-1"><ThreeDots /></button>
            <div className="text-2xl font-bold text-[#3C3C3C]"><p>{value}</p></div>
        </div>
    )
}