export default function LogoutIcon(){
    return (
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<mask id="mask0_115_9007" style={{"maskType":"alpha"}} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="25">
<rect y="0.5" width="24" height="24" fill="#D9D9D9"/>
</mask>
<g mask="url(#mask0_115_9007)">
<path d="M20.15 13.5H8V11.5H20.15L18.6 9.95L20 8.5L24 12.5L20 16.5L18.6 15.05L20.15 13.5ZM15 9.5V5.5H5V19.5H15V15.5H17V19.5C17 20.05 16.8042 20.5208 16.4125 20.9125C16.0208 21.3042 15.55 21.5 15 21.5H5C4.45 21.5 3.97917 21.3042 3.5875 20.9125C3.19583 20.5208 3 20.05 3 19.5V5.5C3 4.95 3.19583 4.47917 3.5875 4.0875C3.97917 3.69583 4.45 3.5 5 3.5H15C15.55 3.5 16.0208 3.69583 16.4125 4.0875C16.8042 4.47917 17 4.95 17 5.5V9.5H15Z" fill="#1C1B1F"/>
</g>
</svg>

    )
}