import React from "react"
import ChartsComp from "../Charts"
// import CustomCard from "../customcard"
// import cardData from "../customcard/cardData"
import { HomeContainer } from "./style"
import { useQuery } from "react-query"
import { loanAnalytics } from "services/LoanService"
import RecentApplications from "./RecentApplications"
// import useAdminData from "hooks/useAdminData"
// import { Link } from "react-router-dom"
// import { DASHBOARD_DRAFTS } from "routes"
import InfoCard from "./HomeCards/infoCards"
import DashboardHeader from "../shared/DashboardHeader"
import { DASHBOARD_DRAFTS } from "routes"

const DashboardHome = () => {
	const { data } = useQuery("analytics", loanAnalytics)
	return (
		<HomeContainer>
			<DashboardHeader title={"Dashboard"}/>
			<section className="grid grid-cols-4 gap-4 mt-[21px]">
				<InfoCard
					loanType={"all"}
					title="Total Applications"
					value={data?.data?.total_applications || "0"}
				/>
				<InfoCard
					loanType={"approved"}
					title="Approved Loans"
					value={data?.data?.approved_loans || "0"}
				/>
				<InfoCard
					loanType={"pending"}
					title="Pending Loans"
					value={data?.data?.pending_loans ||  "0"}
				/>
				<InfoCard
					loanType={"rejected"}
					title="Rejected Loans"
					value={data?.data?.rejected_loans ? data?.data?.rejected_loans : "0"}
				/>
				<InfoCard
					loanType={"disbursed"}
					title="Total Loan Disbursed"
					value={ "₦" + (data?.data?.total_amount_disbursed ? data?.data?.total_amount_disbursed.toLocaleString() : "0")}
				/>
				<InfoCard
					loanType={"repaid"}
					title="Total Loans Repaid"
					value={data?.data?.total_amount_repayed ? data?.data?.total_amount_repayed : "0"}
				/>
				<InfoCard
					loanType={"due"}
					title="Loans Due for Repayment"
					value={data?.data?.due_loans ? data?.data?.due_loans : "0"}
				/>
				<InfoCard
					loanType={"incomplete"}
					url={DASHBOARD_DRAFTS}
					title="Incomplete Application"
					value={data?.data?.incomplete_applications ? data?.data?.incomplete_applications : "0"}
				/>
			</section>
			<section className="mt-[35px]">
				<div className="grid grid-cols-2 gap-4 ">
					<div>
						<ChartsComp />
					</div>
					<div>
						<RecentApplications />
					</div>
				</div>
			</section>
		</HomeContainer>
	)
}

export default DashboardHome
