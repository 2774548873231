function TabButton({label,onChange,isActive}){
	const defaultClassName = "px-8 text py-2 rounded-t-md text-left"
	const activeClassName = "text-primary font-semibold border-b-2 border-primary border-solid"
	if(isActive){
		return (
			<button className={`${defaultClassName} ${activeClassName}`} onClick={onChange}>{label}</button>
		)
	}
	return (
		<button className={defaultClassName} onClick={onChange}>{label}</button>
	)
}

export default function TabHeader({currentTab,selectTab,tabs}){
	return (
		<header className="flex gap-2 w-full border-b border-solid top-0 z-[10]">
		{tabs.map(tab => (
			<TabButton
				onChange={()=>selectTab(tab.index)}
				label={tab.label}
				isActive={tab.index === currentTab}
			/>
		))}
	</header>
	)
}