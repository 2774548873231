export default function DownloadIcon({width, height}) {
    return (
        <svg width={width || "24"} height={height || "24"} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="mask0_74_2186" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                <rect width="24" height="24" fill="currentColor" />
            </mask>
            <g mask="url(#mask0_74_2186)">
                <path d="M4 22V20H20V22H4ZM12 18L5 9H9V2H15V9H19L12 18Z" fill={"currentColor"} />
            </g>
        </svg>

    )
}