import React from 'react'
import { MemoContent, MemoTabDiv, } from './style'
import ExecutiveMemoTable from './MemoTable/ExecutiveMemoTable'

export default function ExecutiveMemoTab() {
    return (
        <MemoTabDiv>
            <MemoContent>
                <ExecutiveMemoTable />
            </MemoContent>
        </MemoTabDiv>
    )
}