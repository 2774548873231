import FilterComp from "components/Dashboard/Loan/FilterComp"
import { TableContainer } from "components/Dashboard/Loan/style"
import React, { useMemo, useState } from "react"
import { useQuery } from "react-query"
import { getExecutiveMemo } from "services/MemoService"
import { MEMO_COLUMNS } from "components/Dashboard/Loan/RefferedLoans/appColumns"
import { usePaginate } from "hooks/usePagination"
import { Table } from "antd"

const ExecutiveMemoTable = () => {
	const [filter, setFilter] = useState("")
	const { data: recMemodata, isLoading } = useQuery("received-memo", getExecutiveMemo)

const columns = useMemo(() => MEMO_COLUMNS, [])
	const data = useMemo(() => {
		const receivedMemo = recMemodata?.data ? recMemodata?.data : []
		return receivedMemo
	}, [recMemodata?.data])

	const filterData = (list)=>{
		return list.filter(item =>{
			const lastName = (item?.admin?.surname).toLowerCase()
			const firstName = (item?.admin?.first_name).toLowerCase()

			const isReady = (firstName.includes(filter.toLowerCase()) ||  lastName.includes(filter.toLowerCase()))
			return isReady
		})

	}

	const {currentPage,next,prev,setPage,pageData} = usePaginate(data)



	return (
		<>
			<TableContainer>
				<FilterComp filter={filter} setFilter={setFilter} />
				<Table
					pagination={{
						current:currentPage,
						onChange:(pageNumber)=>{
							setPage(pageNumber)
						},
						pageSize:10,
						total:data.length,
						nextIcon:()=>{
							return (
								<button onClick={next}> <i className="bi bi-chevron-right"></i></button>
							)
						},
						prevIcon:()=>{
							return (
								<button onClick={prev}> <i className="bi bi-chevron-left"></i></button>
							)
						}
					}}
					scroll={{x: 1300}}
					loading={isLoading}
					dataSource={filterData(pageData)}
					columns={columns}
				/>
			</TableContainer>
		</>
	)
}

export default ExecutiveMemoTable
