import React from 'react'
import ReappUpload from './ReappTable'

const ReappTab = () => {
  return (
    <main>
      <div>
          <ReappUpload />
      </div>
    </main>
  )
}

export default ReappTab
