import React, { useMemo, useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { viewLoanAccount } from 'services/ReappLoanService';
import LoanDetails from './LoanDetails';
import { TableContainer } from 'components/Dashboard/Loan/style';
import SectionLoader from 'components/SectionLoader';
import { REAPPLICANT_COLUMN } from './columns';
import { Table } from 'antd';
import ReapplicantForm from './ReapplicantForm';


const ReappUpload = () => {
  const [loanFormShowing,setLoanFormShowing] = useState(false);

  const openModal = () => { setLoanFormShowing(true); };
  const closeMoal = () => { setLoanFormShowing(false); };

  const [selectedLoanId, setSelectedLoanId] = useState(null);

  const { data: loanData, isLoading, error } = useQuery("loan-account", () => viewLoanAccount(''));

  useEffect(() => {
  }, [loanData]);
  const data = useMemo(() => loanData?.loans || [], [loanData?.loans]);


  if (isLoading) {
    return <SectionLoader />;
  }

  if (error) {
    return <div>Error loading data</div>;
  }


  return (
    <>
      <ReapplicantForm open={loanFormShowing} closeModal={closeMoal}/>
      <header className="flex items-center my-4">
         <div></div>
         <button onClick={openModal} className="ml-auto bg-primary text-white px-7 py-2 rounded-md">Add Reapplicant Record</button>
      </header>
      <TableContainer>
        <Table columns={REAPPLICANT_COLUMN} dataSource={data}/>
      </TableContainer>
      {selectedLoanId && <LoanDetails loanId={selectedLoanId} onClose={() => setSelectedLoanId(null)} />}
	  
    </>
  );
};

export default ReappUpload;
