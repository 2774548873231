import toast from "react-hot-toast"
import loanStore from "mobx/LoanStore"

import { BASE_URL } from "services"
import http from "./httpService"
import apiRoute from "routes/API"
import { AxiosError } from "axios"

export async function addInstitutionUnder(payload) {
	loanStore.loading()
	try {
		// console.log("Payload: ", payload)
		const { data } = await http.post(`${BASE_URL}/re-applicant/admin/institution`, payload)
		toast.success(data.message)
		loanStore.loading()
		// loanStore.setCurrentStepUnder("loan-details")
		return data
	} catch (err) {
		if (err && err.response) {
			loanStore.loading()
			err?.response?.data?.error && toast.error(err.response.data.message)
		} else {
			loanStore.loading()
			toast.error("Network Error")
		}
	}
}
export async function addLoanAccount(payload) {
	try {
		const { data } = await http.post(apiRoute.loanAccount, payload)
		return data
	} catch (error) {
		if(error instanceof AxiosError) return error.response.data
		return {
			status:"error",
			error:true,
			message:"An error occured please try again later"
		}
	}
}
export async function addLoanAccountDetails(payload) {
	loanStore.loading()
	try {
		const { data } = await http.post(apiRoute.loanAccountDetails, payload)
		console.log("Data:", data)
		toast.success(data.msg)
		loanStore.loading()
		return data
	} catch (err) {
		if (err && err.response) {
			console.log("error", err.response)
			loanStore.loading()
			err?.response?.data?.error && toast.error(err.response.data.error)
		} else {
			loanStore.loading()
			toast.error("User Error")
		}
	}
}

// export async function viewLoanAccount(payload) {
// 	loanStore.loading();
// 	try {
// 	  const { data } = await http.get(apiRoute.viewLoanAccount, {
// 		params: { search: payload }
// 	  });
// 	  console.log("Data:", data);
// 	  loanStore.loading();
// 	  return data;
// 	} catch (err) {
// 	  loanStore.loading();
// 	  if (err.response) {
// 		console.log("error", err.response);
// 		toast.error(err.response.data.error);
// 	  } else {
// 		toast.error("User Error");
// 	  }
// 	}
//   }

export async function viewLoanAccount(searchQuery) {
	loanStore.loading();
	try {
	  const { data } = await http.get(apiRoute.viewLoanAccount, {
		params: { search: searchQuery }
	  });
	  loanStore.loading();
	  return data;
	} catch (err) {
	  loanStore.loading();
	  if (err.response) {
		console.log("error", err.response);
		if (err.response.status === 404) {
		  toast.error('No record found');
		} else {
		  toast.error(err.response.data.error);
		}
	  } else {
		toast.error("Network Error");
	  }
	  return { loans: [] }; 
	}
  }
  
  export async function viewLoanAccountDetails(payload) {
	loanStore.loading();
	try {

	  const { data } = await http.get(apiRoute.viewLoanAccountDetails, {
		params: { search: payload.value }
	  });
	  console.log("Data:", data);
	  loanStore.loading();
	  return data;
	} catch (err) {
	  loanStore.loading();
	  if (err.response) {
		console.log("error", err.response);
		toast.error(err.response.data.error);
	  } else {
		toast.error("User Error");
	  }
	}
  }

  // Delete account details check
  export async function deleteLoanAccountDetails(loanId) {
  loanStore.loading();
  try {
    const { data } = await http.delete(apiRoute.deleteLoanAccountDetails,{
			params:{
				id:loanId
			}
		});
    loanStore.loading(); 
    return data;
  } catch (err) {
    loanStore.loading();
    if (err.response) {
      console.log("error", err.response);
      toast.error(err.response.data.error);
    } else {
      toast.error("Error deleting loan account details.");
    }
  }
}


export async function addLoanDetailsUnder(payload) {
	loanStore.loading()
	try {
		const { data } = await http.put(`${BASE_URL}/re-applicant/admin/loans/others`, payload)
		console.log(data)
		toast.success(data.message)
		loanStore.loading()
		loanStore.setCurrentStepUnder("next-of-kin")
		return data
	} catch (err) {
		if (err && err.response) {
			loanStore.loading()
			err?.response?.data?.error && toast.error(err.response.data.message)
		} else {
			loanStore.loading()
			toast.error("Network Error")
		}
	}
}

export async function addLoanGuarantorOne(payload) {
	loanStore.loading()
	try {
		const { data } = await http.put(`${BASE_URL}/re-applicant/admin/loans/guarantor_one`, payload)
		toast.success(data.message)
		loanStore.loading()

		return data
	} catch (err) {
		if (err && err.response) {
			loanStore.loading()
			err?.response?.data?.error && toast.error(err.response.data.message)
		} else {
			loanStore.loading()
			toast.error("Network Error")
		}
	}
}
export async function addLoanGuarantorTwo(payload) {
	loanStore.loading()
	try {
		const { data } = await http.put(`${BASE_URL}/re-applicant/admin/loans/guarantor_two`, payload)
		toast.success(data.message)
		loanStore.loading()

		return data
	} catch (err) {
		if (err && err.response) {
			loanStore.loading()
			err?.response?.data?.error && toast.error(err.response.data.message)
		} else {
			loanStore.loading()
			toast.error("Network Error")
		}
	}
}

export async function addNextOfKinUnder(payload) {
	loanStore.loading()
	try {
		const { data } = await http.put(`${BASE_URL}/re-applicant/admin/loans/undergraduate/next_of_kin`, payload)
		toast.success(data.message)
		loanStore.loading()
		loanStore.setCurrentStepUnder("guarantor")
		return data
	} catch (err) {
		if (err && err.response) {
			loanStore.loading()
			err?.response?.data?.error && toast.error(err.response.data.message)
		} else {
			loanStore.loading()
			toast.error("Network Error")
		}
	}
}

export async function addGuarantorUnder(payload) {
	loanStore.loading()
	try {
		const { data } = await http.put(`${BASE_URL}/re-applicant/admin/loans/undergraduate/guarantor`, payload)
		toast.success(data.message)
		loanStore.loading()
		loanStore.setCurrentStepUnder("file-upload")
		return data
	} catch (err) {
		if (err && err.response) {
			loanStore.loading()
			err?.response?.data?.error && toast.error(err.response.data.message)
		} else {
			loanStore.loading()
			toast.error("Network Error")
		}
	}
}

export async function addFilesPostGrads(payload) {
	loanStore.loading()
	try {
		const { data } = await http.put(`${BASE_URL}/re-applicant/admin/loans/document/postgrads`, payload)
		toast.success(data.message)
		loanStore.loading()
		return data
	} catch (err) {
		if (err && err.response) {
			loanStore.loading()
			err?.response?.data?.error && toast.error(err.response.data.message)
		} else {
			loanStore.loading()
			toast.error("Network Error")
		}
	}
}
export async function addFilesUnder(payload) {
	loanStore.loading()
	try {
		const { data } = await http.put(`${BASE_URL}/re-applicant/admin/loans/document`, payload)
		toast.success(data.message)
		loanStore.loading()
		return data
	} catch (err) {
		if (err && err.response) {
			loanStore.loading()
			err?.response?.data?.error && toast.error(err.response.data.message)
		} else {
			loanStore.loading()
			toast.error("Network Error")
		}
	}
}
export async function addFilesUnderReapply(payload) {
	loanStore.loading()
	try {
		const { data } = await http.put(`${BASE_URL}/re-applicant/admin/loans/document/reapply`, payload)
		toast.success(data.message)
		loanStore.loading()
		return data
	} catch (err) {
		if (err && err.response) {
			loanStore.loading()
			err?.response?.data?.error && toast.error(err.response.data.message)
		} else {
			loanStore.loading()
			toast.error("Network Error")
		}
	}
}
export async function addFilesPostReapply(payload) {
	loanStore.loading()
	try {
		const { data } = await http.put(`${BASE_URL}/re-applicant/admin/loans/document/postgrads/reapply`, payload)
		toast.success(data.message)
		loanStore.loading()
		return data
	} catch (err) {
		if (err && err.response) {
			loanStore.loading()
			err?.response?.data?.error && toast.error(err.response.data.message)
		} else {
			loanStore.loading()
			toast.error("Network Error")
		}
	}
}
export async function addFilesGuarantor(payload) {
	loanStore.loading()
	try {
		const { data } = await http.put(`${BASE_URL}/re-applicant/admin/loans/document/guarantor`, payload)
		toast.success(data.message)
		loanStore.loading()
		return data
	} catch (err) {
		if (err && err.response) {
			loanStore.loading()
			err?.response?.data?.error && toast.error(err.response.data.message)
		} else {
			loanStore.loading()
			toast.error("Network Error")
		}
	}
}

export async function verifyPayment(payload) {
	loanStore.togglePaymentLoading()
	try {
		const { data } = await http.post(`${BASE_URL}/re-applicant/admin/loans/verify_payment`, payload)
		toast.success(data.message)
		loanStore.togglePaymentLoading()
		loanStore.setCurrentStepUnder("verify-bvn")
		return data
	} catch (err) {
		if (err && err.response) {
			loanStore.togglePaymentLoading()
			err?.response?.data?.error && toast.error(err.response.data.message)
		} else {
			loanStore.togglePaymentLoading()
			toast.error("Network Error")
		}
	}
}
