import BackArrow from "components/icons/back-arrow";
import BellIcon from "components/icons/bell";
import LogoutIcon from "components/icons/logout";
import { DateTime } from "luxon";
import { useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getNotifications } from "services/AdminUserService";


export const useAdminNotifications = () => {
    const [loading, setLoading] = useState(true)
    const [notifications, setNotifications] = useState([])
    const getAdminNotifications = useMemo(() => async function () {
        try {
            const response = await getNotifications()
            if(response.data ) {
                const sortedNotifications = response.data.notifications.sort((a,b)=>{
                    const dateA = DateTime.fromJSDate(new Date(a.createdAt))
                    const dateB = DateTime.fromJSDate(new Date(b.createdAt))

                    return dateB.diff(dateA).milliseconds

                })
                setNotifications(sortedNotifications)
            }
        } finally {
            setLoading(false)
        }
    }, [])

    useEffect(() => {
        getAdminNotifications()
    },[getAdminNotifications])

    return { loading, notifications,refetch:getAdminNotifications }
}



function NotificationContainer() {
    const { notifications } = useAdminNotifications()
    const [open, setOpen] = useState(false);

    const unread = notifications.filter(notification => !notification.read)
    const unreadCount = unread.length


    return (
        <div className="relative">
            <div onClick={() => setOpen(prev => !prev)} className="flex cursor-pointer bg-white gap-x-1 rounded-[17px] items-center p-1 pr-4 min-w-[140px]">
                <div className="h-[30px] relative rounded-full w-[30px] flex items-center justify-center bg-[#FFF5E5]">
                    <BellIcon width="24" height="24" />
                    <div className="h-[11px] w-[11px] rounded-full justify-center flex items-center bg-[#FFF5E5] absolute top-1 right-1">
                       {
                        unreadCount > 0 ? (
                            <span className="bg-[#FF9900] h-[6px] w-[6px] rounded-full"></span>
                        ):  <span className="bg-[#575757] h-[6px] w-[6px] rounded-full"></span>
                       }
                    </div>
                </div>
                <p className="text-sm"><b>{unreadCount < 10 ? unreadCount :"10+"}</b> Notifications</p>
            </div>
            {open && (
                <div className="bg-white rounded-[18px] p-4 shadow-lg right-0 z-20 min-w-[450px] absolute top-12">
                    <p className="text-[#959595] text-sm">{} New Notifications</p>
                    <ul className="my-[18px] flex flex-col gap-y-[23px] max-h-[238px] overflow-y-auto mb-[13px]">
                       {unread.slice(0,10).map((notification, index) => {
                        const date = DateTime.fromJSDate(new Date(notification.createdAt)).toRelative();
                        const memoId = notification.payload?.memo_id
                        return (
                            <Link  to={`/memo/memo-details/${memoId}`}>
                                <li className="text-[#959595] text-sm items-center gap-x-[7px]">
                                    <p className="font-bold text-[#140D47]">{notification.message}</p>
                                    <p className="text-xs">{date}</p>
                                </li>
                            </Link>
                          )
                       })}
                    </ul>
                    <footer className="flex justify-end">
                       <Link to={"/notifications"}>
                        <button className="text-[#8A7EE7] text-sm font-bold">More</button>
                       </Link>
                    </footer>
                </div>
            )}
        </div>
    )
}


export default function DashboardHeader({ title = "Dashboard", back = false }) {

    const navigate = useNavigate();

    const goBack = () => navigate(-1);

    return (
        <header className="flex items-center">
            <div className="flex items-center gap-3">
                {back && <button onClick={goBack}><BackArrow /></button>}
                <h3 className="text-lg text-[#3C3C3C] font-bold">{title}</h3>
            </div>
            <div className="ml-auto flex items-center gap-x-4">
                <NotificationContainer />
                <Link to={"/logout"}>
                    <button className="text-[#3C3C3C] flex items-center gap-x-[10px]">
                        <LogoutIcon />
                        <span>Logout</span>
                    </button>
                </Link>
            </div>
        </header>
    )
}