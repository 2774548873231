import Collapsible from "react-collapsible"
const styles ={
    content:{
        background:"#E0E0E0",
        padding:"4px 16px",
        borderRadius:"0px 0px 5px 5px"
    }
}



export default function MemoTitle({note,reciever,sender}){
    return(
        <Collapsible
            trigger={
                <div className="card">
                    {/* <div style={{ display: "flex",width:"100%",justifyContent:"space-between" ,alignItems: "center" }}>
                        {/* <p>
                            {getAdminRole(sender)}
                            &nbsp;&nbsp; &rarr; &nbsp;&nbsp;
                            {getAdminRole(reciever)}
                        </p> 
                        <p style={{fontSize:"16px",fontWeight:"600",textTransform:"uppercase"}}>
                            <span>Recommended for approval</span>
                            <span> - </span>
                            <span>{getAdminRole(sender)}</span>
                        </p>
                        <button style={{background:"none",border:"none"}}>
                            <span style={{ textAlign: "end",}}>Click to view message</span>
                        </button>
                    </div> */}
                </div>
            }
        >
            <div style={styles.content}>
                <p>{note}</p>
            </div>
        </Collapsible>
    )
}